import { PaginationControlledProps } from "@components/PaginationControlled/PaginationControlledTypes";
import {
  StyledPagination,
  StyledPaginationItem,
} from "@components/PaginationControlled/StyledPaginationControlled";
import { Stack } from "@mui/material";

const PaginationControlled = ({
  wrapperStackProps,
  shape = "rounded",
  color = "primary",
  ...rest
}: PaginationControlledProps) => {
  return (
    <Stack {...wrapperStackProps}>
      <StyledPagination
        shape={shape}
        color={color}
        renderItem={(item) => <StyledPaginationItem {...item} />}
        {...rest}
      />
    </Stack>
  );
};
export default PaginationControlled;
