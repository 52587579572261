import CreateUpdateSubtitle from "@components/CreateUpdateSubtitle/CreateUpdateSubtitle";
import StyledReactHookFormInput from "@components/StyledReactHookFormInput/StyledReactHookFormInput";
import Typography from "@components/Typography/Typography";
import { Box, Stack } from "@mui/material";

const MainEmployeeInfo = () => {
  return (
    <div>
      <CreateUpdateSubtitle number="1" subtitle="Основная информация" />
      <Box sx={{ marginTop: "60px" }}>
        <Stack direction="column" spacing={"30px"}>
          <Stack direction="column" spacing={"15px"}>
            <Typography variant="body1" fontWeight="fontWeightBold">
              Имя сотрудника:
            </Typography>

            <StyledReactHookFormInput
              name="employerName"
              rules={{
                required: "Поле обязательно для заполнения.",
              }}
            />
          </Stack>

          <Stack direction="column" spacing={"15px"}>
            <Typography variant="body1" fontWeight="fontWeightBold">
              Должность сотрудника:
            </Typography>

            <StyledReactHookFormInput
              name="name"
              rules={{
                required: "Поле обязательно для заполнения.",
              }}
            />
          </Stack>

          <Stack direction="column" spacing={"15px"}>
            <Typography variant="body1" fontWeight="fontWeightBold">
              Описание:
            </Typography>

            <StyledReactHookFormInput name="description" />
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};
export default MainEmployeeInfo;
