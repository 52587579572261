import { IconButtonProps } from "@components/IconButton/IconButtonTypes";
import { StyledIconButton } from "@components/IconButton/StyledIconButton";

const IconButton = ({
  className,
  size = "large",
  backgroundColor = "rgba(240, 96, 84, 0.25)",
  icon,
  color,
  ...restIconButtonProps
}: IconButtonProps) => {
  return (
    <StyledIconButton
      color={color}
      size={size}
      backgroundColor={backgroundColor}
      {...restIconButtonProps}
    >
      {icon}
    </StyledIconButton>
  );
};
export default IconButton;
