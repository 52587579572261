import { User } from "@redux/features/userSlice/userSliceTypes";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { resetTokens } from "@utils/cookieToken";

import { UserState } from "./userSliceTypes";

const initialState: UserState = {
  user: null,
};

export const userSlice = createSlice({
  initialState,
  name: "userSlice",
  reducers: {
    logout: () => {
      resetTokens();
      return initialState;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
});

export default userSlice.reducer;

export const { logout, setUser } = userSlice.actions;
