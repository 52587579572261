import { useMemo } from "react";

import { SelectWithStyledOptionsProps } from "@components/SelectWithStyledOptions/SelectWithStyledOptionsTypes";
import {
  StyledReactHookFormSelect,
  StyledSelectItem,
} from "@components/SelectWithStyledOptions/StyledSelectWithStyledOptions";
import Typography from "@components/Typography/Typography";
import { borderRadius } from "@constants/constants.style";

const selectMenuStyleProps = {
  MenuProps: {
    PaperProps: {
      style: {
        boxShadow: "none",
        border: "1px solid rgba(39, 39, 39, 0.05)",
        borderRadius: borderRadius,
      },
    },
  },
};

const SelectWithStyledOptions = ({
  name,
  className,
  options,
  SelectProps,
  ...rest
}: SelectWithStyledOptionsProps) => {
  const selectOptions = useMemo(() => {
    if (!options) {
      return (
        <StyledSelectItem>
          <Typography variant="body1" textAlign="center">
            Нет вариантов
          </Typography>
        </StyledSelectItem>
      );
    }

    return options.map((item) => {
      return (
        <StyledSelectItem key={item.value} value={item.value}>
          <Typography variant="body1" textAlign="center">
            {item.label}
          </Typography>
        </StyledSelectItem>
      );
    });
  }, [options]);

  return (
    <StyledReactHookFormSelect
      name={name}
      SelectProps={{
        ...SelectProps,
        ...selectMenuStyleProps,
      }}
      {...rest}
    >
      {selectOptions}
    </StyledReactHookFormSelect>
  );
};
export default SelectWithStyledOptions;
