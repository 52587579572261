import {
  CreateInfoMessageBody,
  GetLastInfoMessageResponse,
} from "@customTypes/api/models/infoMessage.model";
import { InfoMessageApiPaths } from "@redux/services/infoMessage/infoMessageApiPaths";
import generalApi from "@redux/services/initialApi";

export const infoMessageApi = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    getLastInfoMessage: builder.query<GetLastInfoMessageResponse, void>({
      query: () => InfoMessageApiPaths.InfoMessageLast,
      providesTags: (result) =>
        result
          ? [
              { type: "InfoMessages", id: result.id } as const,
              { type: "InfoMessages", id: "LIST" },
            ]
          : [{ type: "InfoMessages", id: "LIST" }],
    }),

    createInfoMessage: builder.mutation<void, CreateInfoMessageBody>({
      query: ({ ...createInfoMessageBody }) => ({
        url: InfoMessageApiPaths.InfoMessageAdmin,
        method: "POST",
        body: createInfoMessageBody,
      }),
      invalidatesTags: [{ type: "InfoMessages", id: "LIST" }],
    }),
  }),
});

export const { useGetLastInfoMessageQuery, useCreateInfoMessageMutation } =
  infoMessageApi;
