import CreateUpdateSubtitle from "@components/CreateUpdateSubtitle/CreateUpdateSubtitle";
import SelectWithStyledOptions from "@components/SelectWithStyledOptions/SelectWithStyledOptions";
import StyledReactHookFormInput from "@components/StyledReactHookFormInput/StyledReactHookFormInput";
import { CURRENCY_SELECT_OPTIONS } from "@constants/constants";
import { Box, Stack, Typography } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";

const CreateUpdateProductPrice = () => {
  const { control } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: "prices",
  });

  const pricesFields = fields.map((item, index) => (
    <Stack direction="column" spacing={"15px"} key={item.id}>
      <Typography variant="body1" fontWeight="fontWeightBold">
        {index === 0 ? "Цена за тонну:" : "Цена за 20 тонн:"}
      </Typography>

      <Stack direction="row" spacing={"15px"} alignItems="center">
        <StyledReactHookFormInput
          name={`prices.${index}.price`}
          maxWidth="600px"
          fullWidth
          isNumberInput
        />

        <SelectWithStyledOptions
          name={`prices.${index}.currency`}
          options={CURRENCY_SELECT_OPTIONS}
        />
      </Stack>
    </Stack>
  ));

  return (
    <div>
      <CreateUpdateSubtitle number="2" subtitle="Цена товара" />

      <Box sx={{ marginTop: "60px" }}>
        <Stack direction="column" spacing={"30px"}>
          {pricesFields}
        </Stack>
      </Box>
    </div>
  );
};
export default CreateUpdateProductPrice;
