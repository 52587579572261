import {
  GetDataWithPagination,
  PaginationParams,
} from "@customTypes/api/general";
import {
  CreateAcidBody,
  GetAcidByIdApiResponse,
  GetAcidsApiResponse,
  UpdateAcidBody,
} from "@customTypes/api/models/acid.model";
import { AcidApiPaths } from "@redux/services/acid/acidApiPaths";
import generalApi from "@redux/services/initialApi";

export const acidApi = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    getAcidById: builder.query<GetAcidByIdApiResponse, number>({
      query: (id) => `${AcidApiPaths.Acid}/${id}`,
    }),

    deleteAcid: builder.mutation<boolean, number>({
      query: (id) => ({
        url: AcidApiPaths.Acid,
        method: "DELETE",
        params: { id: id },
      }),
      invalidatesTags: [{ type: "Acids", id: "LIST" }],
    }),

    getAcidsOnPage: builder.query<
      GetDataWithPagination<GetAcidsApiResponse>,
      PaginationParams
    >({
      query: (params) => ({
        url: AcidApiPaths.GetAcidsOnPage,
        params: { ...params },
      }),

      providesTags: (result) =>
        result
          ? [
              ...result.content.map(({ id }) => ({
                type: "Acids" as const,
                id,
              })),
              { type: "Acids", id: "LIST" },
            ]
          : [{ type: "Acids", id: "LIST" }],
    }),

    createAcid: builder.mutation<void, CreateAcidBody>({
      query: ({ ...acidBody }) => ({
        url: AcidApiPaths.CreateAcid,
        body: acidBody,
        method: "POST",
        headers: {
          Authorization: "admin:password",
        },
      }),
      invalidatesTags: [{ type: "Acids", id: "LIST" }],
    }),

    updateAcid: builder.mutation<void, UpdateAcidBody>({
      query: ({ ...acidBody }) => ({
        url: AcidApiPaths.UpdateAcid,
        body: acidBody,
        method: "PUT",
        headers: {
          Authorization: "admin:password",
        },
      }),
      invalidatesTags: [{ type: "Acids", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAcidsOnPageQuery,
  useCreateAcidMutation,
  useDeleteAcidMutation,
  useLazyGetAcidByIdQuery,
  useUpdateAcidMutation,
} = acidApi;
