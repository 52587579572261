import { useCallback, useEffect, useState } from "react";

import MainContainer from "@components/MainContainer/MainContainer";
import StyledSubmitButton from "@components/StyledSubmitButton/StyledSubmitButton";
import { AcidFileServerModel } from "@customTypes/api/models/acid.model";
import { CategoryServerModal } from "@customTypes/api/models/category.model";
import { FileServerModel } from "@customTypes/api/models/file.model";
import { ManufacturerServerModel } from "@customTypes/api/models/manufacturer.model";
import { Box, Stack } from "@mui/material";
import CreateUpdateProductCertificates from "@pages/ProductsPage/pages/CreateUpdateProduct/components/CreateUpdateProductCertificates/CreateUpdateProductCertificates";
import CreateUpdateProductCharacteristics from "@pages/ProductsPage/pages/CreateUpdateProduct/components/CreateUpdateProductCharacteristics/CreateUpdateProductCharacteristics";
import CreateUpdateProductMainInfo from "@pages/ProductsPage/pages/CreateUpdateProduct/components/CreateUpdateProductMainInfo/CreateUpdateProductMainInfo";
import CreateUpdateProductPrice from "@pages/ProductsPage/pages/CreateUpdateProduct/components/CreateUpdateProductPrice/CreateUpdateProductPrice";
import { FormValues } from "@pages/ProductsPage/pages/CreateUpdateProduct/CreateUpdateProductTypes";
import {
  useCreateAcidMutation,
  useLazyGetAcidByIdQuery,
  useUpdateAcidMutation,
} from "@redux/services/acid/acidApi";
import { useLazyGetCategoryByIdQuery } from "@redux/services/category/categoryApi";
import {
  useCreateFileMutation,
  useCreateImagesMutation,
} from "@redux/services/file/fileApi";
import { useLazyGetManufacturerByIdQuery } from "@redux/services/manufacturer/manufacturerApi";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { slugify } from "transliteration";

const defaultFormValues: FormValues = {
  name: "",
  frontName: "",
  gost: "",
  acidFiles: "",
  count: "",
  description: "",
  category: "",
  acidDensity: "",
  manufacturer: "",
  availableTares: [],
  prices: [
    { price: "", currency: "RUB", id: "", count: 0 },
    { price: "", currency: "RUB", id: "", count: 0 },
  ],
  characteristics: [
    {
      id: "",
      name: "",
      value: "",
      description: "",
      priority: "",
    },
  ],
  certificates: [{ frontName: "", file: "", name: "", id: "" }],
};

const CreateUpdateProduct = () => {
  /**
   * * Libraries
   */
  const methods = useForm<FormValues>({
    defaultValues: { ...defaultFormValues },
  });

  const { handleSubmit, reset } = methods;

  const { productId } = useParams();

  /**
   * * Local State
   */

  const [acidFiles, setAcidFiles] = useState<AcidFileServerModel[] | null>(
    null
  );

  /**
   * * Api services
   */
  const [createAcid] = useCreateAcidMutation();

  const [createFile] = useCreateFileMutation();

  const [createImages] = useCreateImagesMutation();

  const [getAcid, { data: fetchedAcidById }] = useLazyGetAcidByIdQuery();

  const [getManufacturer] = useLazyGetManufacturerByIdQuery();

  const [getCategory] = useLazyGetCategoryByIdQuery();

  const [updateAcid] = useUpdateAcidMutation();

  /**
   * * Component's useEffect
   */

  /**
   * In useEffect below we transform and substitute the received data into the form
   */
  useEffect(() => {
    if (!fetchedAcidById) return;
    if (fetchedAcidById.acidFiles && fetchedAcidById.acidFiles.length > 0) {
      setAcidFiles(fetchedAcidById.acidFiles);
    }

    const { name, description, count, gost, prices, acidDensity, frontName } =
      fetchedAcidById;

    const acidPriceFormValue =
      prices.length > 0 ? prices : defaultFormValues.prices;

    const acidCharacteristicsFormValue = fetchedAcidById.characteristics.map(
      (elem) => {
        return {
          id: elem.id || "",
          name: elem.name || "",
          value: elem.value || "",
          description: elem.description || "",
          priority: elem.priority,
        };
      }
    );

    const categoryIDFormValue = fetchedAcidById.category
      ? String(fetchedAcidById.category.id)
      : "";

    const manufacturerIDFormValue = fetchedAcidById.manufacturer
      ? String(fetchedAcidById.manufacturer.id)
      : "";

    const certificatesFormValue = fetchedAcidById.certificates?.map((elem) => {
      return {
        frontName: elem.frontName,
        file: "",
        name: elem.name,
        id: elem.id,
        priority: elem.priority,
      };
    });

    const availableTaresFormValue = fetchedAcidById.availableTares.map((elem) =>
      String(elem.id)
    );

    reset({
      ...defaultFormValues,
      name: name,
      frontName: frontName || slugify(name),
      acidDensity: acidDensity,
      description: description,
      gost: gost,
      count: count,
      prices: acidPriceFormValue,
      characteristics: acidCharacteristicsFormValue,
      category: categoryIDFormValue,
      manufacturer: manufacturerIDFormValue,
      availableTares: availableTaresFormValue,
      certificates: certificatesFormValue,
    });
  }, [fetchedAcidById, reset]);

  /**
   * Get acid if we have productId in the URL params
   */
  useEffect(() => {
    if (!productId) return;
    getAcid(Number(productId));
  }, [getAcid, productId]);

  /**
   * * Api services handlers
   */

  /**
   * Get Category and transformation response exclude acids field
   * @param categoryId
   */
  const handleGetCategory = useCallback(
    async (categoryId: number) => {
      const response = await getCategory(categoryId).unwrap();

      const { acids, ...restCategory } = response;
      return restCategory;
    },
    [getCategory]
  );

  /**
   * Get Manufacturer and transformation response exclude acid field
   * @param manufacturerId
   */
  const handleGetManufacturer = useCallback(
    async (manufacturerId: number) => {
      const response = await getManufacturer(manufacturerId).unwrap();

      const { acid, ...restManufacturer } = response;
      return restManufacturer;
    },
    [getManufacturer]
  );

  /**
   * * Update Acid Product
   * @param formData
   */
  const updateProduct = async (formData: FormValues) => {
    if (!fetchedAcidById) return;

    /**
     * Define manufacturer and category and images
     */
    let manufacturerValue: Omit<ManufacturerServerModel, "acid"> | null = null;

    let categoryValue: Omit<CategoryServerModal, "acids"> | null = null;

    let images: FileServerModel[] | null = null;

    /**
     * Get manufacturer and category to use all entity fields to update product
     * Documentation: http://51.250.55.223:30036/swagger-ui/swagger-ui/index.html#/%D0%9A%D0%B8%D1%81%D0%BB%D0%BE%D1%82%D0%BD%D1%8B%D0%B9%20%D0%BA%D0%BE%D0%BD%D1%82%D1%80%D0%BE%D0%BB%D0%BB%D0%B5%D1%80/update
     */
    if (formData.category) {
      categoryValue = await handleGetCategory(Number(formData.category));
    }

    if (formData.manufacturer) {
      manufacturerValue = await handleGetManufacturer(
        Number(formData.manufacturer)
      );
    }

    /**
     * * Create images from form data
     */
    if (
      formData.acidFiles &&
      formData.acidFiles.length > 0 &&
      typeof formData.acidFiles !== "string"
    ) {
      images = await createImages({
        multipartFiles: formData.acidFiles,
      }).unwrap();
    }

    /**
     * Take acidFiles from local state from fetchedAcid and new images from form data
     */
    const imagesWithExistAcidImages = (): AcidFileServerModel[] | null => {
      if (
        (!acidFiles || acidFiles.length < 1) &&
        (!images || images.length < 1)
      ) {
        return null;
      }

      if ((!acidFiles || acidFiles.length < 1) && images && images.length > 0) {
        return images;
      }

      if ((!images || images.length < 1) && acidFiles && acidFiles.length > 0) {
        return acidFiles;
      }

      return [...acidFiles!, ...images!];
    };

    /**
     * Create certificates with single handleCreateFile function,
     * because we need to have the frontName
     */
    const certificateNames = await Promise.all(
      formData.certificates?.map(async (elem) => {
        if (!elem.file || elem.file.length < 1 || typeof elem.file === "string")
          return;
        return await createFile({
          file: elem.file![0],
          frontName: elem.frontName,
        }).unwrap();
      })
    );

    /**
     * Filter certificates to exclude undefined value from array
     */
    const filteredCertificates = certificateNames.filter(
      (elem) => typeof elem !== "undefined"
    );

    /**
     * Transform and Filter certificates to have values from fetchedAcid
     * and not the ones we created
     */
    const acidCertificates = formData.certificates
      .map((elem) => {
        const { file, ...restElem } = elem;
        return {
          ...restElem,
        };
      })
      .filter((elem) => elem.id !== null && elem.name);

    /**
     * Take certificates from fetchedAcid and new certificates from form data
     */
    const certificatesWithExistAcidCertificates = ():
      | FileServerModel[]
      | null => {
      if (
        (!acidCertificates || acidCertificates.length < 1) &&
        filteredCertificates.length < 1
      )
        return null;

      if (
        (!acidCertificates || acidCertificates.length < 1) &&
        filteredCertificates &&
        filteredCertificates.length > 0
      ) {
        return filteredCertificates as FileServerModel[];
      }

      if (
        filteredCertificates.length < 1 &&
        acidCertificates &&
        acidCertificates.length > 0
      ) {
        return acidCertificates as FileServerModel[];
      }

      return [
        ...(acidCertificates! as FileServerModel[]),
        ...(filteredCertificates as FileServerModel[]),
      ];
    };

    /**
     * Transform prices field to update acid
     */
    const productPrices = formData.prices.map((elem) => {
      return {
        ...elem,
        price: elem.price ? Number(elem.price) : null,
        id: elem.id ? Number(elem.id) : null,
      };
    });

    /**
     * Transform characteristics to update acid
     */
    const updateCharacteristics = formData.characteristics.map((elem) => {
      return {
        ...elem,
        id: elem.id ? Number(elem.id) : null,
        priority: elem.priority ? Number(elem.priority) : null,
      };
    });

    /**
     * Transform availableTares to update acid
     */
    const updateAvailableTares = formData.availableTares.map((elem) => {
      return {
        id: Number(elem),
      };
    });

    /**
     * * Create body for updateAcid
     * TODO: think about price types, in PUT method "id" is required, but in types it is not
     */
    const body = {
      ...formData,
      priority: fetchedAcidById.priority,
      prices: productPrices,
      characteristics: updateCharacteristics,
      count: formData.count ? Number(formData.count) : null,
      id: fetchedAcidById.id,
      acidDensity: formData.acidDensity ? Number(formData.acidDensity) : null,
      category: categoryValue,
      manufacturer: manufacturerValue,
      availableTares: updateAvailableTares,
      certificates: certificatesWithExistAcidCertificates(),
      acidFiles: imagesWithExistAcidImages(),
    };

    updateAcid(body);
  };

  /**
   * * Create Acid Product
   * @param formData
   */
  const createProduct = async (formData: FormValues) => {
    /**
     * Define images, if there are no acidFiles in the form data, then null by default
     */
    let images: FileServerModel[] | null = null;

    /**
     * Create images from form data
     */
    if (
      formData.acidFiles &&
      formData.acidFiles.length > 0 &&
      typeof formData.acidFiles !== "string"
    ) {
      images = await createImages({
        multipartFiles: formData.acidFiles,
      }).unwrap();
    }

    /**
     * Take image name, it is enough to create just acid
     * Documentation: http://51.250.55.223:30036/swagger-ui/swagger-ui/index.html#/%D0%9A%D0%B8%D1%81%D0%BB%D0%BE%D1%82%D0%BD%D1%8B%D0%B9%20%D0%BA%D0%BE%D0%BD%D1%82%D1%80%D0%BE%D0%BB%D0%BB%D0%B5%D1%80/createNewObjectFromBody
     */
    const imageNames = images?.map((image) => image.name);

    /**
     * Create certificates with single handleCreateFile function,
     * because we need to have the frontName
     */
    const certificates = await Promise.all(
      formData.certificates?.map(async (elem) => {
        if (!elem.file || elem.file.length < 1 || typeof elem.file === "string")
          return;
        return await createFile({
          file: elem.file![0],
          frontName: elem.frontName,
        }).unwrap();
      })
    );

    /**
     * Filter certificates to exclude undefined value from array
     */
    const filteredCertificateNames = certificates
      .map((elem) => elem?.name)
      .filter((elem) => typeof elem !== "undefined");

    /**
     * Transform prices to exclude id value from array to create acid and transform price field
     */
    const acidPrices = formData.prices.map((elem) => {
      return {
        price: elem.price ? Number(elem.price) : null,
        currency: elem.currency,
        count: elem.count,
      };
    });

    /**
     * Transform characteristics to exclude id value from array to create acid
     */
    const createCharacteristics = formData.characteristics.map((elem) => {
      const { id, priority, ...restElem } = elem;
      return {
        ...restElem,
      };
    });

    /**
     * Transform availableTares to create acid
     */

    const createAvailableTares = formData.availableTares.map((elem) => {
      return {
        id: Number(elem),
      };
    });

    /**
     * Create body for createAcid
     */
    const body = {
      ...formData,
      acidDensity: formData.acidDensity ? Number(formData.acidDensity) : null,
      count: formData.count ? Number(formData.count) : null,
      prices: acidPrices,
      characteristics: createCharacteristics,
      category: formData.category
        ? {
            id: Number(formData.category),
          }
        : null,
      manufacturer: formData.manufacturer
        ? {
            id: Number(formData.manufacturer),
          }
        : null,
      availableTares: createAvailableTares,
      certificates:
        filteredCertificateNames.length > 0
          ? (filteredCertificateNames as string[])
          : null,
      acidFiles: imageNames || null,
    };

    createAcid(body);

    /**
     * Reset form fields after creating acid
     */
    reset();
  };

  /**
   * * Handlers
   */
  const handleDeleteEditableAcidFile = useCallback(
    (id: number) => {
      if (!acidFiles) return;
      const filteredAcidFiles = acidFiles.filter((elem) => elem.id !== id);
      setAcidFiles(filteredAcidFiles);
    },
    [acidFiles]
  );

  const handleSubmitFormData = async (data: FormValues) => {
    if (productId) {
      updateProduct(data);
      return;
    }
    createProduct(data);
  };

  /**
   * * Layouts
   */
  return (
    <MainContainer>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(handleSubmitFormData)}
          encType="multipart/form-data"
        >
          <Stack direction="column" spacing={"60px"}>
            <CreateUpdateProductMainInfo
              acidFiles={acidFiles}
              handleDeleteEditableAcidFile={handleDeleteEditableAcidFile}
            />
            <CreateUpdateProductPrice />
            <CreateUpdateProductCharacteristics />
            {/**
             * //TODO: fix helper text in input file if the rules exist
             */}
            <CreateUpdateProductCertificates />
          </Stack>

          <Box sx={{ marginTop: "60px", marginBottom: "120px" }}>
            <StyledSubmitButton />
          </Box>
        </form>
      </FormProvider>
    </MainContainer>
  );
};
export default CreateUpdateProduct;
