import RouterLink from "@components/RouterLink/RouterLink";
import { borderRadius } from "@constants/constants.style";
import { Box, styled } from "@mui/material";

export const StyledCommonCardEntityBoxContainer = styled(Box)`
  border-radius: ${borderRadius};
  width: 100%;
  background: ${({ theme }) => theme.palette.common.white};
  padding: 20px;
`;

export const StyledCommonCardEntityImageBoxWrapper = styled(Box)`
  max-width: 100px;
`;

export const StyledCommonCardEntityImage = styled("img")`
  width: 100%;
  height: 100%;
  border-radius: ${borderRadius};
`;

export const StyledRouterLink = styled(RouterLink, {
  shouldForwardProp: (prop) => prop !== "isDisabledEditButton",
})<{ isDisabledEditButton: boolean }>`
  cursor: ${({ isDisabledEditButton }) =>
    isDisabledEditButton ? "default" : "pointer"};
`;
