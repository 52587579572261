const RoutePaths = {
  MainPage: "/",
  LoginPage: "/login",
  ProductsPage: "/products",
  CreateProduct: "/products/create",
  UpdateProduct: "/products/:productId/edit",
  CategoriesPage: "/categories",
  CreateCategory: "/categories/create",
  UpdateCategory: "/categories/:categoryId/edit",
  ManufacturersPage: "/manufacturers",
  CreateManufacturer: "/manufacturers/create",
  UpdateManufacturer: "/manufacturers/:manufacturerId/edit",
  EmployeesPage: "/employees",
  CreateEmployee: "/employees/create",
  ContactsPage: "/contacts",
  CreateContact: "/contacts/create",
  ExtraActionPage: "/extraActions",
  CreateExtraAction: "/extraActions/create",
  TaresPage: "/tares",
  CreateTare: "/tares/create",
  UpdateTare: "/tares/:tareId/edit",
  InfoMessagePage: "/info-message",
  CreateInfoMessage: "/info-message/create",
  NotFoundPage: "/*",
};

export default RoutePaths;
