import theme from "@config/theme";

export type PrimaryLogoIconProps = {
  width?: number;
  height?: number;
  dark?: boolean;
};

const PrimaryLogo = ({
  height = 82,
  width = 416,
  dark = false,
}: PrimaryLogoIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 416 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Нижегородский завод фосфорных кислот"
    >
      <path
        d="M20.3096 6.01704C22.7162 6.01704 24.4844 6.52049 25.614 7.52739C26.76 8.51805 27.333 9.82539 27.333 11.4494C27.333 12.5213 27.0465 13.5119 26.4735 14.4214C25.9169 15.3146 25.0492 16.0454 23.8704 16.6138C22.6917 17.166 21.2264 17.4421 19.4746 17.4421C18.2631 17.4421 17.0516 17.3122 15.8401 17.0523C14.645 16.7925 13.5972 16.4108 12.6968 15.9074L14.6614 11.6687C15.3817 12.0747 16.1348 12.3832 16.9207 12.5944C17.7229 12.8055 18.5005 12.911 19.2536 12.911C19.9249 12.911 20.4651 12.7974 20.8744 12.57C21.3001 12.3264 21.5129 11.9935 21.5129 11.5712C21.5129 11.1652 21.3246 10.8648 20.9481 10.6699C20.5715 10.4588 19.8921 10.3532 18.9098 10.3532H13.9247L14.7842 0H26.2034V4.33616H19.5483L19.4255 6.01704H20.3096Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M9.70172 0V17.0523H3.90617V4.33616H0.959279V0H9.70172Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M39.2043 17.7796H50.7968V71.4698H39.2043V17.7796Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M62.3886 62.1262C77.638 62.1262 90 49.8633 90 34.7363C90 19.6092 77.638 7.34635 62.3886 7.34635V17.8904C71.7676 17.8904 79.3707 25.4326 79.3707 34.7363C79.3707 44.0399 71.7676 51.5821 62.3886 51.5821V62.1262Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M27.6114 82C12.362 82 0 69.7371 0 54.6101C0 39.4831 12.362 27.2202 27.6114 27.2202V37.7643C18.2325 37.7643 10.6293 45.3064 10.6293 54.6101C10.6293 63.9138 18.2325 71.4559 27.6114 71.4559V82Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M373.775 52.3657H368.875V63.7711H366.214V52.3657H361.314V49.8621H373.775V52.3657Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M353.079 64.0491C351.698 64.0491 350.437 63.7338 349.299 63.1033C348.16 62.4542 347.264 61.5826 346.61 60.4884C345.957 59.3757 345.63 58.1517 345.63 56.8164C345.63 55.4812 345.957 54.2664 346.61 53.1723C347.264 52.0595 348.16 51.1879 349.299 50.5574C350.437 49.9083 351.698 49.5837 353.079 49.5837C354.442 49.5837 355.683 49.9083 356.804 50.5574C357.942 51.1879 358.839 52.0595 359.492 53.1723C360.145 54.2664 360.472 55.4812 360.472 56.8164C360.472 58.1517 360.145 59.3757 359.492 60.4884C358.839 61.5826 357.942 62.4542 356.804 63.1033C355.683 63.7338 354.442 64.0491 353.079 64.0491ZM353.079 61.6011C353.938 61.6011 354.722 61.3971 355.431 60.9891C356.141 60.5626 356.701 59.9877 357.112 59.2644C357.522 58.5226 357.728 57.7066 357.728 56.8164C357.728 55.9263 357.522 55.1195 357.112 54.3963C356.701 53.6544 356.141 53.0795 355.431 52.6715C354.722 52.245 353.938 52.0317 353.079 52.0317C352.202 52.0317 351.399 52.245 350.671 52.6715C349.961 53.0795 349.401 53.6544 348.991 54.3963C348.58 55.1195 348.375 55.9263 348.375 56.8164C348.375 57.7066 348.58 58.5226 348.991 59.2644C349.401 59.9877 349.961 60.5626 350.671 60.9891C351.399 61.3971 352.202 61.6011 353.079 61.6011Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M342.398 63.7989H339.738V52.3657H333.745V54.8971C333.745 56.8815 333.633 58.5042 333.409 59.7653C333.204 61.0264 332.765 62.0371 332.093 62.7974C331.421 63.5393 330.422 63.9102 329.096 63.9102C328.742 63.9102 328.359 63.8638 327.948 63.7711V61.3231C328.284 61.3787 328.583 61.4065 328.844 61.4065C329.442 61.4065 329.89 61.2211 330.189 60.8502C330.506 60.4793 330.73 59.8209 330.861 58.8751C330.991 57.9107 331.057 56.5013 331.057 54.6468V49.8621H342.398V63.7989Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M313.142 56.8164C313.142 55.4812 313.469 54.2664 314.122 53.1723C314.776 52.0595 315.672 51.1879 316.811 50.5574C317.949 49.9083 319.21 49.5837 320.591 49.5837C322.141 49.5837 323.531 50.0381 324.764 50.9468C326.014 51.837 326.836 53.0054 327.228 54.4519H324.315C323.998 53.7101 323.485 53.1259 322.775 52.6994C322.085 52.2543 321.31 52.0317 320.451 52.0317C319.574 52.0317 318.789 52.245 318.099 52.6715C317.408 53.0795 316.867 53.6544 316.475 54.3963C316.082 55.1195 315.886 55.9263 315.886 56.8164C315.886 57.7066 316.082 58.5226 316.475 59.2644C316.867 59.9877 317.408 60.5626 318.099 60.9891C318.789 61.3971 319.574 61.6011 320.451 61.6011C321.31 61.6011 322.085 61.3879 322.775 60.9613C323.485 60.5162 323.998 59.9228 324.315 59.181H327.228C326.836 60.6275 326.014 61.8051 324.764 62.7139C323.531 63.604 322.141 64.0491 320.591 64.0491C319.21 64.0491 317.949 63.7338 316.811 63.1033C315.672 62.4542 314.776 61.5826 314.122 60.4884C313.469 59.3757 313.142 58.1517 313.142 56.8164Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M297.037 49.8621H299.697V60.2104L306.978 49.8621H309.919V63.7711H307.258V53.4228L299.977 63.7711H297.037V49.8621Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M285.96 57.3173L283.971 59.1533V63.7711H281.367V49.8621H283.971V56.0933L290.552 49.8621H294.081L287.948 55.4813L294.669 63.7711H291.112L285.96 57.3173Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M267.695 63.7711L263.634 58.5135L259.546 63.7711H256.326L261.926 56.7053L256.55 49.8621H259.854L263.69 54.8415L267.443 49.8621H270.747L265.399 56.6218L270.999 63.7711H267.695Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M243.927 55.37C245.402 55.37 246.55 55.7688 247.371 56.5662C248.212 57.3451 248.632 58.3466 248.632 59.5705C248.632 60.7945 248.212 61.8053 247.371 62.6027C246.55 63.3816 245.402 63.7711 243.927 63.7711H237.01V49.8621H239.67V55.37H243.927ZM243.563 61.4622C244.328 61.4622 244.907 61.3045 245.299 60.9893C245.691 60.674 245.887 60.2011 245.887 59.5705C245.887 58.94 245.691 58.4671 245.299 58.1518C244.907 57.8366 244.328 57.6789 243.563 57.6789H239.67V61.4622H243.563ZM253.812 49.8621V63.7711H251.152V49.8621H253.812Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M232.52 49.8621V63.7711H229.859V58.0684H222.578V63.7711H219.918V49.8621H222.578V55.5648H229.859V49.8621H232.52Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M210.082 49.5837C211.314 49.5837 212.434 49.899 213.442 50.5296C214.45 51.1415 215.244 51.9946 215.823 53.0888C216.401 54.183 216.691 55.4255 216.691 56.8164C216.691 58.2073 216.401 59.4499 215.823 60.544C215.244 61.6382 214.45 62.5006 213.442 63.1311C212.434 63.7431 211.314 64.0491 210.082 64.0491C209.148 64.0491 208.271 63.8451 207.45 63.4371C206.628 63.0106 205.928 62.4264 205.349 61.6846V68.5H202.689V49.8619H205.209V52.1152C205.807 51.3177 206.525 50.6965 207.366 50.2514C208.206 49.8063 209.111 49.5837 210.082 49.5837ZM209.634 61.6011C210.455 61.6011 211.193 61.3971 211.846 60.9891C212.5 60.5811 213.013 60.0155 213.386 59.2922C213.76 58.5504 213.946 57.7252 213.946 56.8164C213.946 55.9077 213.76 55.0917 213.386 54.3684C213.013 53.6266 212.5 53.0517 211.846 52.6437C211.193 52.2357 210.455 52.0317 209.634 52.0317C208.812 52.0317 208.066 52.2357 207.394 52.6437C206.721 53.0517 206.199 53.6266 205.825 54.3684C205.452 55.0917 205.265 55.9077 205.265 56.8164C205.265 57.7252 205.452 58.5504 205.825 59.2922C206.199 60.0155 206.721 60.5811 207.394 60.9891C208.066 61.3971 208.812 61.6011 209.634 61.6011Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M192.087 64.0491C190.706 64.0491 189.446 63.7338 188.307 63.1033C187.168 62.4542 186.272 61.5826 185.618 60.4884C184.965 59.3757 184.638 58.1517 184.638 56.8164C184.638 55.4812 184.965 54.2664 185.618 53.1723C186.272 52.0595 187.168 51.1879 188.307 50.5574C189.446 49.9083 190.706 49.5837 192.087 49.5837C193.45 49.5837 194.692 49.9083 195.812 50.5574C196.95 51.1879 197.847 52.0595 198.5 53.1723C199.153 54.2664 199.48 55.4812 199.48 56.8164C199.48 58.1517 199.153 59.3757 198.5 60.4884C197.847 61.5826 196.95 62.4542 195.812 63.1033C194.692 63.7338 193.45 64.0491 192.087 64.0491ZM192.087 61.6011C192.946 61.6011 193.73 61.3971 194.439 60.9891C195.149 60.5626 195.709 59.9877 196.12 59.2644C196.53 58.5226 196.736 57.7066 196.736 56.8164C196.736 55.9263 196.53 55.1195 196.12 54.3963C195.709 53.6544 195.149 53.0795 194.439 52.6715C193.73 52.245 192.946 52.0317 192.087 52.0317C191.21 52.0317 190.407 52.245 189.679 52.6715C188.969 53.0795 188.409 53.6544 187.999 54.3963C187.588 55.1195 187.383 55.9263 187.383 56.8164C187.383 57.7066 187.588 58.5226 187.999 59.2644C188.409 59.9877 188.969 60.5626 189.679 60.9891C190.407 61.3971 191.21 61.6011 192.087 61.6011Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M175.234 49.8617C176.578 49.8617 177.811 50.1677 178.931 50.7797C180.051 51.3917 180.928 52.2355 181.563 53.3111C182.217 54.3682 182.543 55.5365 182.543 56.8162C182.543 58.0958 182.217 59.2734 181.563 60.3491C180.928 61.4062 180.051 62.2407 178.931 62.8527C177.811 63.4647 176.578 63.7707 175.234 63.7707H173.722V68.4998H171.062V63.7707H169.606C168.243 63.7707 167.001 63.4647 165.881 62.8527C164.761 62.2407 163.874 61.4062 163.221 60.3491C162.567 59.2734 162.241 58.0958 162.241 56.8162C162.241 55.5365 162.567 54.3682 163.221 53.3111C163.874 52.2355 164.761 51.3917 165.881 50.7797C167.001 50.1677 168.243 49.8617 169.606 49.8617H171.062V44.159H173.722V49.8617H175.234ZM171.062 61.3227V52.3097H169.69C168.812 52.3097 168.009 52.5137 167.281 52.9217C166.572 53.3111 166.012 53.8489 165.601 54.5351C165.19 55.2213 164.985 55.9816 164.985 56.8162C164.985 57.6507 165.19 58.4111 165.601 59.0973C166.012 59.7834 166.572 60.3305 167.281 60.7385C168.009 61.128 168.812 61.3227 169.69 61.3227H171.062ZM175.15 61.3227C176.009 61.3227 176.793 61.128 177.503 60.7385C178.212 60.3305 178.772 59.7834 179.183 59.0973C179.594 58.4111 179.799 57.6507 179.799 56.8162C179.799 55.9816 179.594 55.2213 179.183 54.5351C178.772 53.8489 178.212 53.3111 177.503 52.9217C176.793 52.5137 176.009 52.3097 175.15 52.3097H173.722V61.3227H175.15Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M146.325 56.8164C146.325 55.4812 146.652 54.2664 147.305 53.1723C147.959 52.0595 148.855 51.1879 149.994 50.5574C151.133 49.9083 152.393 49.5837 153.774 49.5837C155.324 49.5837 156.715 50.0381 157.947 50.9468C159.198 51.837 160.019 53.0054 160.411 54.4519H157.499C157.181 53.7101 156.668 53.1259 155.959 52.6994C155.268 52.2543 154.493 52.0317 153.634 52.0317C152.757 52.0317 151.973 52.245 151.282 52.6715C150.591 53.0795 150.05 53.6544 149.658 54.3963C149.266 55.1195 149.07 55.9263 149.07 56.8164C149.07 57.7066 149.266 58.5226 149.658 59.2644C150.05 59.9877 150.591 60.5626 151.282 60.9891C151.973 61.3971 152.757 61.6011 153.634 61.6011C154.493 61.6011 155.268 61.3879 155.959 60.9613C156.668 60.5162 157.181 59.9228 157.499 59.181H160.411C160.019 60.6275 159.198 61.8051 157.947 62.7139C156.715 63.604 155.324 64.0491 153.774 64.0491C152.393 64.0491 151.133 63.7338 149.994 63.1033C148.855 62.4542 147.959 61.5826 147.305 60.4884C146.652 59.3757 146.325 58.1517 146.325 56.8164Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M136.846 64.0491C135.465 64.0491 134.205 63.7338 133.066 63.1033C131.927 62.4542 131.031 61.5826 130.378 60.4884C129.724 59.3757 129.398 58.1517 129.398 56.8164C129.398 55.4812 129.724 54.2664 130.378 53.1723C131.031 52.0595 131.927 51.1879 133.066 50.5574C134.205 49.9083 135.465 49.5837 136.846 49.5837C138.209 49.5837 139.451 49.9083 140.571 50.5574C141.71 51.1879 142.606 52.0595 143.259 53.1723C143.913 54.2664 144.239 55.4812 144.239 56.8164C144.239 58.1517 143.913 59.3757 143.259 60.4884C142.606 61.5826 141.71 62.4542 140.571 63.1033C139.451 63.7338 138.209 64.0491 136.846 64.0491ZM136.846 61.6011C137.705 61.6011 138.489 61.3971 139.199 60.9891C139.908 60.5626 140.468 59.9877 140.879 59.2644C141.29 58.5226 141.495 57.7066 141.495 56.8164C141.495 55.9263 141.29 55.1195 140.879 54.3963C140.468 53.6544 139.908 53.0795 139.199 52.6715C138.489 52.245 137.705 52.0317 136.846 52.0317C135.969 52.0317 135.166 52.245 134.438 52.6715C133.729 53.0795 133.169 53.6544 132.758 54.3963C132.347 55.1195 132.142 55.9263 132.142 56.8164C132.142 57.7066 132.347 58.5226 132.758 59.2644C133.169 59.9877 133.729 60.5626 134.438 60.9891C135.166 61.3971 135.969 61.6011 136.846 61.6011Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M119.994 49.8617C121.338 49.8617 122.57 50.1677 123.69 50.7797C124.81 51.3917 125.688 52.2355 126.322 53.3111C126.976 54.3682 127.302 55.5365 127.302 56.8162C127.302 58.0958 126.976 59.2734 126.322 60.3491C125.688 61.4062 124.81 62.2407 123.69 62.8527C122.57 63.4647 121.338 63.7707 119.994 63.7707H118.481V68.4998H115.821V63.7707H114.365C113.002 63.7707 111.761 63.4647 110.64 62.8527C109.52 62.2407 108.634 61.4062 107.98 60.3491C107.327 59.2734 107 58.0958 107 56.8162C107 55.5365 107.327 54.3682 107.98 53.3111C108.634 52.2355 109.52 51.3917 110.64 50.7797C111.761 50.1677 113.002 49.8617 114.365 49.8617H115.821V44.159H118.481V49.8617H119.994ZM115.821 61.3227V52.3097H114.449C113.571 52.3097 112.769 52.5137 112.041 52.9217C111.331 53.3111 110.771 53.8489 110.36 54.5351C109.95 55.2213 109.744 55.9816 109.744 56.8162C109.744 57.6507 109.95 58.4111 110.36 59.0973C110.771 59.7834 111.331 60.3305 112.041 60.7385C112.769 61.128 113.571 61.3227 114.449 61.3227H115.821ZM119.91 61.3227C120.768 61.3227 121.552 61.128 122.262 60.7385C122.971 60.3305 123.531 59.7834 123.942 59.0973C124.353 58.4111 124.558 57.6507 124.558 56.8162C124.558 55.9816 124.353 55.2213 123.942 54.5351C123.531 53.8489 122.971 53.3111 122.262 52.9217C121.552 52.5137 120.768 52.3097 119.91 52.3097H118.481V61.3227H119.91Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M416 30.4688V36.0324H413.424V32.9725H402.726V36.0324H400.15V30.4688H401.942C402.334 29.3561 402.53 27.2234 402.53 24.0707V19.0634H414.04V30.4688H416ZM411.379 30.5245V21.5114H405.191V23.9872C405.191 26.769 404.976 28.9481 404.547 30.5245H411.379Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M391.503 33.2505C390.121 33.2505 388.861 32.9352 387.722 32.3047C386.583 31.6556 385.687 30.784 385.034 29.6898C384.38 28.5771 384.054 27.3531 384.054 26.0178C384.054 24.6825 384.38 23.4678 385.034 22.3736C385.687 21.2609 386.583 20.3893 387.722 19.7588C388.861 19.1097 390.121 18.7851 391.503 18.7851C392.865 18.7851 394.107 19.1097 395.227 19.7588C396.366 20.3893 397.262 21.2609 397.915 22.3736C398.569 23.4678 398.896 24.6825 398.896 26.0178C398.896 27.3531 398.569 28.5771 397.915 29.6898C397.262 30.784 396.366 31.6556 395.227 32.3047C394.107 32.9352 392.865 33.2505 391.503 33.2505ZM391.503 30.8025C392.361 30.8025 393.146 30.5985 393.855 30.1905C394.564 29.764 395.124 29.1891 395.535 28.4658C395.946 27.724 396.151 26.908 396.151 26.0178C396.151 25.1276 395.946 24.3209 395.535 23.5976C395.124 22.8558 394.564 22.2809 393.855 21.8729C393.146 21.4464 392.361 21.2331 391.503 21.2331C390.625 21.2331 389.822 21.4464 389.094 21.8729C388.385 22.2809 387.825 22.8558 387.414 23.5976C387.003 24.3209 386.798 25.1276 386.798 26.0178C386.798 26.908 387.003 27.724 387.414 28.4658C387.825 29.1891 388.385 29.764 389.094 30.1905C389.822 30.5985 390.625 30.8025 391.503 30.8025Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M379.88 25.8789C381.261 26.565 381.952 27.6221 381.952 29.0501C381.952 30.1999 381.532 31.1457 380.692 31.8876C379.852 32.6108 378.694 32.9725 377.219 32.9725H370.19V19.0634H377.219C378.62 19.0634 379.721 19.4251 380.524 20.1483C381.345 20.8531 381.756 21.7525 381.756 22.8467C381.756 23.4958 381.597 24.0892 381.28 24.627C380.981 25.1463 380.514 25.5636 379.88 25.8789ZM376.911 24.8496C377.602 24.8496 378.125 24.692 378.48 24.3767C378.834 24.0614 379.012 23.5978 379.012 22.9858C379.012 21.8545 378.311 21.2889 376.911 21.2889H372.795V24.8496H376.911ZM376.939 30.747C377.686 30.747 378.246 30.5894 378.62 30.2741C379.012 29.9403 379.208 29.4674 379.208 28.8554C379.208 28.2805 379.012 27.8447 378.62 27.5479C378.246 27.2327 377.686 27.075 376.939 27.075H372.795V30.747H376.939Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M365.703 19.0633V32.9723H363.043V30.886C362.464 31.6278 361.764 32.212 360.943 32.6385C360.121 33.0465 359.244 33.2505 358.31 33.2505C357.078 33.2505 355.958 32.9445 354.95 32.3325C353.942 31.702 353.148 30.8396 352.57 29.7454C351.991 28.6513 351.702 27.4087 351.702 26.0178C351.702 24.6269 351.991 23.3844 352.57 22.2902C353.148 21.196 353.942 20.3429 354.95 19.7309C355.958 19.1004 357.078 18.7851 358.31 18.7851C359.281 18.7851 360.187 19.0077 361.027 19.4528C361.867 19.8978 362.586 20.5191 363.183 21.3166V19.0633H365.703ZM358.758 30.8025C359.58 30.8025 360.327 30.5985 360.999 30.1905C361.671 29.7825 362.194 29.2169 362.567 28.4936C362.94 27.7518 363.127 26.9265 363.127 26.0178C363.127 25.1091 362.94 24.2931 362.567 23.5698C362.194 22.828 361.671 22.2531 360.999 21.8451C360.327 21.4371 359.58 21.2331 358.758 21.2331C357.937 21.2331 357.2 21.4371 356.546 21.8451C355.893 22.2531 355.379 22.828 355.006 23.5698C354.633 24.2931 354.446 25.1091 354.446 26.0178C354.446 26.9265 354.633 27.7518 355.006 28.4936C355.379 29.2169 355.893 29.7825 356.546 30.1905C357.2 30.5985 357.937 30.8025 358.758 30.8025Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M339.834 28.5771C340.076 29.3931 340.524 29.9958 341.178 30.3852C341.85 30.7747 342.727 30.9694 343.81 30.9694C344.818 30.9694 345.63 30.7932 346.246 30.4409C346.862 30.07 347.17 29.5971 347.17 29.0222C347.17 28.5214 346.946 28.0949 346.498 27.7425C346.05 27.3716 345.285 27.1862 344.202 27.1862H341.766V24.9051H344.09C345.061 24.9051 345.752 24.7289 346.162 24.3765C346.592 24.0242 346.806 23.5976 346.806 23.0969C346.806 22.4664 346.517 21.9657 345.938 21.5947C345.378 21.2238 344.603 21.0384 343.614 21.0384C342.662 21.0384 341.868 21.2146 341.234 21.5669C340.618 21.9193 340.216 22.4756 340.03 23.236H337.397C337.64 21.8451 338.34 20.7602 339.497 19.9813C340.655 19.1838 342.027 18.7851 343.614 18.7851C345.369 18.7851 346.788 19.1653 347.87 19.9257C348.953 20.6675 349.495 21.6504 349.495 22.8744C349.495 23.4864 349.327 24.0613 348.991 24.5991C348.655 25.1184 348.151 25.5356 347.478 25.8509C349.028 26.4629 349.803 27.5107 349.803 28.9943C349.803 30.274 349.243 31.3032 348.123 32.0821C347.021 32.861 345.518 33.2505 343.614 33.2505C341.896 33.2505 340.44 32.8332 339.245 31.9987C338.051 31.1642 337.36 30.0236 337.173 28.5771H339.834Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M313.5 19.0636H316.16V29.4119L323.441 19.0636H326.382V32.9726H323.721V22.6243L316.44 32.9726H313.5V19.0636ZM319.941 17.7005C318.485 17.7005 317.29 17.3203 316.356 16.56C315.423 15.7996 314.9 14.7796 314.788 13.5H317.085C317.178 14.2047 317.477 14.7611 317.981 15.1691C318.485 15.5585 319.138 15.7533 319.941 15.7533C320.744 15.7533 321.397 15.5585 321.901 15.1691C322.405 14.7611 322.704 14.2047 322.797 13.5H325.094C324.982 14.7796 324.459 15.7996 323.525 16.56C322.592 17.3203 321.397 17.7005 319.941 17.7005Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M296.134 19.0634H298.794V29.4117L306.075 19.0634H309.016V32.9725H306.355V22.6241L299.074 32.9725H296.134V19.0634Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M285.057 26.5187L283.069 28.3547V32.9725H280.464V19.0634H283.069V25.2947L289.649 19.0634H293.178L287.045 24.6827L293.766 32.9725H290.209L285.057 26.5187Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M263.425 26.0178C263.425 24.6825 263.752 23.4678 264.405 22.3736C265.059 21.2609 265.955 20.3893 267.093 19.7588C268.232 19.1097 269.492 18.7851 270.874 18.7851C272.423 18.7851 273.814 19.2395 275.046 20.1482C276.297 21.0384 277.119 22.2067 277.511 23.6533H274.598C274.281 22.9115 273.768 22.3273 273.058 21.9007C272.367 21.4557 271.593 21.2331 270.734 21.2331C269.856 21.2331 269.072 21.4464 268.382 21.8729C267.691 22.2809 267.149 22.8558 266.757 23.5976C266.365 24.3209 266.169 25.1276 266.169 26.0178C266.169 26.908 266.365 27.724 266.757 28.4658C267.149 29.1891 267.691 29.764 268.382 30.1905C269.072 30.5985 269.856 30.8025 270.734 30.8025C271.593 30.8025 272.367 30.5892 273.058 30.1627C273.768 29.7176 274.281 29.1242 274.598 28.3823H277.511C277.119 29.8289 276.297 31.0065 275.046 31.9152C273.814 32.8054 272.423 33.2505 270.874 33.2505C269.492 33.2505 268.232 32.9352 267.093 32.3047C265.955 31.6556 265.059 30.784 264.405 29.6898C263.752 28.5771 263.425 27.3531 263.425 26.0178Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M262.146 30.4688V36.0324H259.569V32.9725H248.872V36.0324H246.296V30.4688H248.088C248.48 29.3561 248.676 27.2234 248.676 24.0707V19.0634H260.185V30.4688H262.146ZM257.525 30.5245V21.5114H251.336V23.9872C251.336 26.769 251.122 28.9481 250.692 30.5245H257.525Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M237.647 33.2505C236.266 33.2505 235.006 32.9352 233.867 32.3047C232.728 31.6556 231.832 30.784 231.178 29.6898C230.525 28.5771 230.198 27.3531 230.198 26.0178C230.198 24.6825 230.525 23.4678 231.178 22.3736C231.832 21.2609 232.728 20.3893 233.867 19.7588C235.006 19.1097 236.266 18.7851 237.647 18.7851C239.01 18.7851 240.252 19.1097 241.372 19.7588C242.511 20.3893 243.407 21.2609 244.06 22.3736C244.713 23.4678 245.04 24.6825 245.04 26.0178C245.04 27.3531 244.713 28.5771 244.06 29.6898C243.407 30.784 242.511 31.6556 241.372 32.3047C240.252 32.9352 239.01 33.2505 237.647 33.2505ZM237.647 30.8025C238.506 30.8025 239.29 30.5985 240 30.1905C240.709 29.764 241.269 29.1891 241.68 28.4658C242.09 27.724 242.296 26.908 242.296 26.0178C242.296 25.1276 242.09 24.3209 241.68 23.5976C241.269 22.8558 240.709 22.2809 240 21.8729C239.29 21.4464 238.506 21.2331 237.647 21.2331C236.77 21.2331 235.967 21.4464 235.239 21.8729C234.53 22.2809 233.969 22.8558 233.559 23.5976C233.148 24.3209 232.943 25.1276 232.943 26.0178C232.943 26.908 233.148 27.724 233.559 28.4658C233.969 29.1891 234.53 29.764 235.239 30.1905C235.967 30.5985 236.77 30.8025 237.647 30.8025Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M221.486 18.7851C222.718 18.7851 223.838 19.1004 224.847 19.7309C225.855 20.3429 226.648 21.196 227.227 22.2902C227.806 23.3844 228.095 24.6269 228.095 26.0178C228.095 27.4087 227.806 28.6513 227.227 29.7454C226.648 30.8396 225.855 31.702 224.847 32.3325C223.838 32.9445 222.718 33.2505 221.486 33.2505C220.553 33.2505 219.675 33.0465 218.854 32.6385C218.032 32.212 217.332 31.6278 216.754 30.886V37.7014H214.093V19.0633H216.614V21.3166C217.211 20.5191 217.93 19.8978 218.77 19.4528C219.61 19.0077 220.515 18.7851 221.486 18.7851ZM221.038 30.8025C221.859 30.8025 222.597 30.5985 223.25 30.1905C223.904 29.7825 224.417 29.2169 224.79 28.4936C225.164 27.7518 225.351 26.9265 225.351 26.0178C225.351 25.1091 225.164 24.2931 224.79 23.5698C224.417 22.828 223.904 22.2531 223.25 21.8451C222.597 21.4371 221.859 21.2331 221.038 21.2331C220.217 21.2331 219.47 21.4371 218.798 21.8451C218.126 22.2531 217.603 22.828 217.23 23.5698C216.856 24.2931 216.67 25.1091 216.67 26.0178C216.67 26.9265 216.856 27.7518 217.23 28.4936C217.603 29.2169 218.126 29.7825 218.798 30.1905C219.47 30.5985 220.217 30.8025 221.038 30.8025Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M203.491 33.2505C202.11 33.2505 200.85 32.9352 199.711 32.3047C198.572 31.6556 197.676 30.784 197.023 29.6898C196.369 28.5771 196.043 27.3531 196.043 26.0178C196.043 24.6825 196.369 23.4678 197.023 22.3736C197.676 21.2609 198.572 20.3893 199.711 19.7588C200.85 19.1097 202.11 18.7851 203.491 18.7851C204.854 18.7851 206.096 19.1097 207.216 19.7588C208.355 20.3893 209.251 21.2609 209.904 22.3736C210.558 23.4678 210.884 24.6825 210.884 26.0178C210.884 27.3531 210.558 28.5771 209.904 29.6898C209.251 30.784 208.355 31.6556 207.216 32.3047C206.096 32.9352 204.854 33.2505 203.491 33.2505ZM203.491 30.8025C204.35 30.8025 205.134 30.5985 205.844 30.1905C206.553 29.764 207.113 29.1891 207.524 28.4658C207.935 27.724 208.14 26.908 208.14 26.0178C208.14 25.1276 207.935 24.3209 207.524 23.5976C207.113 22.8558 206.553 22.2809 205.844 21.8729C205.134 21.4464 204.35 21.2331 203.491 21.2331C202.614 21.2331 201.811 21.4464 201.083 21.8729C200.374 22.2809 199.814 22.8558 199.403 23.5976C198.992 24.3209 198.787 25.1276 198.787 26.0178C198.787 26.908 198.992 27.724 199.403 28.4658C199.814 29.1891 200.374 29.764 201.083 30.1905C201.811 30.5985 202.614 30.8025 203.491 30.8025Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M195.18 21.5671H188.039V32.9725H185.379V19.0634H195.18V21.5671Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M175.044 18.7851C176.481 18.7851 177.732 19.0911 178.796 19.7031C179.879 20.3151 180.71 21.1867 181.288 22.318C181.867 23.4307 182.157 24.7382 182.157 26.2404C182.157 26.6298 182.147 26.9173 182.129 27.1027H170.647C170.834 28.1783 171.338 29.0685 172.159 29.7732C172.999 30.4594 173.97 30.8025 175.072 30.8025C176.883 30.8025 178.171 30.1442 178.936 28.8274H181.681C181.232 30.1256 180.402 31.192 179.188 32.0265C177.975 32.8425 176.603 33.2505 175.072 33.2505C173.709 33.2505 172.477 32.9445 171.375 32.3325C170.274 31.702 169.415 30.8396 168.799 29.7454C168.183 28.6327 167.875 27.3902 167.875 26.0178C167.875 24.6455 168.183 23.4122 168.799 22.318C169.415 21.2053 170.264 20.3429 171.347 19.7309C172.449 19.1004 173.681 18.7851 175.044 18.7851ZM174.988 21.1218C173.886 21.1218 172.934 21.4649 172.131 22.1511C171.347 22.8373 170.862 23.7182 170.675 24.7938H179.272C179.16 23.6811 178.703 22.7909 177.9 22.1233C177.116 21.4557 176.145 21.1218 174.988 21.1218Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M159.544 26.5743L157.808 28.2434V32.9725H155.176V28.2434L153.44 26.5743L148.679 32.9725H145.375L151.507 24.7105L145.655 19.0634H148.987L155.176 25.0721V19.0634H157.808V25.1L164.053 19.0634H167.329L161.477 24.7105L167.609 32.9725H164.305L159.544 26.5743Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M130.111 19.0634H132.771V29.4117L140.052 19.0634H142.992V32.9725H140.332V22.6241L133.051 32.9725H130.111V19.0634Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
      <path
        d="M125.342 13.5V32.9726H122.541V24.5716H111.34V32.9726H108.54V13.5H111.34V21.901H122.541V13.5H125.342Z"
        fill={dark ? "#5D5D5D" : theme.palette.secondary.main}
      />
    </svg>
  );
};
export default PrimaryLogo;
