import ReactHookFormInput from "@components/form-components/ReactHookFormInput/ReactHookFormInput";
import { borderRadius } from "@constants/constants.style";
import { styled } from "@mui/material";

const StyledReactHookFormInput = styled(ReactHookFormInput, {
  shouldForwardProp: (prop) => prop !== "maxWidth",
})<{ maxWidth?: string }>`
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "inherit")};

  .MuiOutlinedInput-notchedOutline {
    border-radius: ${borderRadius};
  }

  .MuiOutlinedInput-input {
    font-size: 16px;
  }
`;

export default StyledReactHookFormInput;
