import { useCallback, useState } from "react";

import AddButtonLink from "@components/AddButtonLink/AddButtonLink";
import CommonCardEntity from "@components/CommonCardEntity/CommonCardEntity";
import MainContainer from "@components/MainContainer/MainContainer";
import PaginationControlled from "@components/PaginationControlled/PaginationControlled";
import Typography from "@components/Typography/Typography";
import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import { Box, Stack } from "@mui/material";
import {
  useGetContactsOnPageQuery,
  useDeleteContactMutation,
} from "@redux/services/contact/contactApi";
import RoutePaths from "@routes/routePaths";

const ContactsPage = () => {
  //  * Local state
  const [currentPage, setCurrentPage] = useState(0);

  // * Api services
  const {
    data: fetchedContacts,
    isError: isFetchedContactsError,
    isLoading: isFetchedContactsLoading,
  } = useGetContactsOnPageQuery({
    page: currentPage,
    size: 5,
  });

  const [deleteContact] = useDeleteContactMutation();

  // * Handlers
  const handleDeleteContact = useCallback(
    (contactId: number) => {
      deleteContact(contactId);
    },
    [deleteContact]
  );

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value - 1);
  };

  // * Layouts
  const contactsCards = fetchedContacts?.content?.map((elem) => {
    return (
      <CommonCardEntity
        key={elem.id}
        id={elem.id}
        title={elem.name}
        isDisabledEditButton
        handleDeleteButton={handleDeleteContact}
      />
    );
  });

  return (
    <MainContainer sx={{ marginBottom: "100px" }}>
      <Stack
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography variant="h3">Контакты</Typography>

        <AddButtonLink title="Добавить контакт" to={RoutePaths.CreateContact} />
      </Stack>

      <WithLoader isLoading={isFetchedContactsLoading}>
        <WithErrorMessage
          isError={isFetchedContactsError}
          stackProps={{
            style: { margin: "200px 0" },
          }}
        >
          <Stack direction="column" sx={{ marginTop: "30px" }} spacing={"60px"}>
            {contactsCards}
          </Stack>
          {fetchedContacts?.content && fetchedContacts?.content.length > 0 && (
            <Box sx={{ marginTop: "60px" }}>
              <PaginationControlled
                wrapperStackProps={{ spacing: "30px", alignItems: "end" }}
                count={fetchedContacts.totalPages}
                // ?NOTE: currentPage + 1, because the data is returned from page 0, it is needed to sync pages
                page={currentPage + 1}
                onChange={handlePaginationChange}
              />
            </Box>
          )}
        </WithErrorMessage>
      </WithLoader>
    </MainContainer>
  );
};
export default ContactsPage;
