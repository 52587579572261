import { useMemo } from "react";

import { CreateUpdateImageBlockProps } from "@components/CreateUpdateImageBlock/CreateUpdateImageBlockTypes";
import {
  StyledInputFileLabel,
  StyledCreateUpdateImageBlockReactHookFormInput,
  StyledPreviewImage,
  StyledPreviewImageContainer,
} from "@components/CreateUpdateImageBlock/StyledCreateUpdateImageBlock";
import Typography from "@components/Typography/Typography";
import theme from "@config/theme";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { IconButton, Stack } from "@mui/material";
import generateFileSrc from "@utils/generateFileSrc";
import imageOnErrorHandler from "@utils/handleImageError";
import { useFormContext } from "react-hook-form";

const CreateUpdateImageBlock = ({
  className,
  title,
  name,
  label,
  defaultValue,
  rules,
  inputProps,
  previewImages,
  handleDeleteEditablePreviewImages,
  ...restProps
}: CreateUpdateImageBlockProps) => {
  const { watch } = useFormContext();

  const images: FileList = watch(name);

  const previewLinks = useMemo(() => {
    if (!images) return;
    if (images.length === 1) {
      return URL.createObjectURL(images[0]);
    }
    const arrayFileList = Array.from(images);
    return arrayFileList.map((item) => URL.createObjectURL(item));
  }, [images]);

  const editPreviewImages = useMemo(() => {
    if (!previewImages) return;

    if (!Array.isArray(previewImages)) {
      return (
        <StyledPreviewImageContainer>
          <div
            style={{
              position: "relative",
            }}
          >
            <StyledPreviewImage
              sx={{ maxHeight: "200px" }}
              src={generateFileSrc(previewImages.name)}
              onError={(event) => imageOnErrorHandler(event)}
              alt="preview"
            />
            <IconButton
              sx={{ position: "absolute", bottom: "0", right: "0" }}
              onClick={
                handleDeleteEditablePreviewImages
                  ? () => handleDeleteEditablePreviewImages(previewImages.id)
                  : () => {}
              }
            >
              <DeleteIcon htmlColor={theme.palette.error.light} />
            </IconButton>
          </div>
        </StyledPreviewImageContainer>
      );
    }

    return previewImages.map((elem) => {
      return (
        <StyledPreviewImageContainer key={elem.id}>
          <div
            style={{
              position: "relative",
            }}
          >
            <StyledPreviewImage
              sx={{ maxHeight: "200px" }}
              src={generateFileSrc(elem.name)}
              onError={(event) => imageOnErrorHandler(event)}
              alt="preview"
            />
            <IconButton
              sx={{ position: "absolute", bottom: "0", right: "0" }}
              onClick={
                handleDeleteEditablePreviewImages
                  ? () => handleDeleteEditablePreviewImages(elem.id)
                  : () => {}
              }
            >
              <DeleteIcon htmlColor={theme.palette.error.light} />
            </IconButton>
          </div>
        </StyledPreviewImageContainer>
      );
    });
  }, [previewImages, handleDeleteEditablePreviewImages]);

  const previewImagesContent = () => {
    if (!previewLinks) return;

    if (!Array.isArray(previewLinks)) {
      return (
        <StyledPreviewImageContainer>
          <StyledPreviewImage src={previewLinks} alt="preview" />
        </StyledPreviewImageContainer>
      );
    }
    return previewLinks?.map((link) => (
      <StyledPreviewImageContainer key={link}>
        <StyledPreviewImage src={link} alt="preview" />
      </StyledPreviewImageContainer>
    ));
  };

  return (
    <Stack direction="column" spacing={"15px"}>
      <Typography variant="body1" fontWeight="fontWeightBold">
        {title}
      </Typography>

      <Stack direction="row" spacing={"15px"}>
        {editPreviewImages}
        {previewImagesContent()}

        <StyledCreateUpdateImageBlockReactHookFormInput
          name={name}
          type="file"
          inputProps={{ ...inputProps }}
          defaultValue={defaultValue}
          rules={{ ...rules }}
          formControlLabelProps={{ sx: { marginLeft: 0 } }}
          {...restProps}
          label={
            <StyledInputFileLabel>
              <NoteAddOutlinedIcon color="primary" sx={{ fontSize: "3em" }} />
            </StyledInputFileLabel>
          }
        />
      </Stack>
    </Stack>
  );
};
export default CreateUpdateImageBlock;
