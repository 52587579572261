import { isRejected } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

/**
 * Log data from error
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejected()(action)) {
      const { error, status, message } = (action.payload as any).data;
      toast.error(`Error: ${error}, Status: ${status}`, {
        position: "top-right",
      });
      // eslint-disable-next-line no-console
      console.log("error: ", error, "status: ", status, "message: ", message);
    }
    return next(action);
  };
