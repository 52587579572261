import { Stack } from "@mui/material";
import { EntitiesNavigationListProps } from "@pages/MainPage/components/EntitiesNavigationList/EntitiesNavigationListTypes";
import EntityNavigationBlock from "@pages/MainPage/components/EntityNavigationBlock/EntityNavigationBlock";

const EntitiesNavigationList = ({ items }: EntitiesNavigationListProps) => {
  const navigationItems = items.map((elem) => (
    <EntityNavigationBlock key={elem.title} title={elem.title} to={elem.to} />
  ));

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      {navigationItems}
    </Stack>
  );
};
export default EntitiesNavigationList;
