import {
  CreateEmployeeBody,
  GetEmployeesResponse,
} from "@customTypes/api/models/employee.model";
import { EmployeeApiPaths } from "@redux/services/employee/employeeApiPaths";
import generalApi from "@redux/services/initialApi";

export const employeeApi = generalApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getEmployees: builder.query<GetEmployeesResponse, void>({
        query: () => EmployeeApiPaths.ContactTeam,
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Employees" as const,
                  id,
                })),
                { type: "Employees", id: "LIST" },
              ]
            : [{ type: "Employees", id: "LIST" }],
      }),

      createEmployee: builder.mutation<void, CreateEmployeeBody>({
        query: ({ ...createEmployeeContactBody }) => ({
          url: EmployeeApiPaths.ContactAdminEmployee,
          method: "POST",
          body: createEmployeeContactBody,
        }),
        invalidatesTags: [{ type: "Employees", id: "LIST" }],
      }),

      deleteEmployee: builder.mutation<boolean, number>({
        query: (employeeId) => ({
          url: EmployeeApiPaths.ContactAdminEmployee,
          method: "DELETE",
          params: { id: employeeId },
        }),
        invalidatesTags: [{ type: "Employees", id: "LIST" }],
      }),
    };
  },
});

export const {
  useGetEmployeesQuery,
  useCreateEmployeeMutation,
  useDeleteEmployeeMutation,
} = employeeApi;
