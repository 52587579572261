import AddButtonLink from "@components/AddButtonLink/AddButtonLink";
import CommonCardEntity from "@components/CommonCardEntity/CommonCardEntity";
import MainContainer from "@components/MainContainer/MainContainer";
import Typography from "@components/Typography/Typography";
import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import { Stack } from "@mui/material";
import { useGetLastInfoMessageQuery } from "@redux/services/infoMessage/infoMessageApi";
import RoutePaths from "@routes/routePaths";
const InfoMessagePage = () => {
  // * Api services
  const {
    data: fetchedInfoMessage,
    isError: isInfoMessageError,
    isLoading: isInfoMessageLoading,
  } = useGetLastInfoMessageQuery();
  return (
    <MainContainer sx={{ marginBottom: "100px" }}>
      <Stack
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography variant="h3">Popup сообщение</Typography>

        <AddButtonLink
          title="Добавить сообщение"
          to={RoutePaths.CreateInfoMessage}
        />
      </Stack>

      <WithLoader isLoading={isInfoMessageLoading}>
        <WithErrorMessage
          isError={isInfoMessageError}
          stackProps={{
            style: { margin: "200px 0" },
          }}
        >
          <Stack direction="column" sx={{ marginTop: "30px" }} spacing={"60px"}>
            {fetchedInfoMessage && (
              <CommonCardEntity
                key={fetchedInfoMessage.id}
                id={fetchedInfoMessage.id}
                title={fetchedInfoMessage.message}
                isDisabledEditButton
                isDisabledDeleteButton
                handleDeleteButton={() => {}}
              />
            )}
          </Stack>
        </WithErrorMessage>
      </WithLoader>
    </MainContainer>
  );
};
export default InfoMessagePage;
