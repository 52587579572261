import {
  CreateManufacturerBody,
  GetManufacturerApiResponse,
  GetManufacturersApiResponse,
  UpdateManufacturerBody,
} from "@customTypes/api/models/manufacturer.model";
import generalApi from "@redux/services/initialApi";

import { ManufacturerApiPaths } from "./manufacturerApiPaths";

export const manufacturerApi = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    getManufacturers: builder.query<GetManufacturersApiResponse, void>({
      query: () => ManufacturerApiPaths.GetManufacturerAll,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Manufacturers" as const,
                id,
              })),
              { type: "Manufacturers", id: "LIST" },
            ]
          : [{ type: "Manufacturers", id: "LIST" }],
    }),

    getManufacturerById: builder.query<GetManufacturerApiResponse, number>({
      query: (id) => ManufacturerApiPaths.Manufacturer + id,
    }),

    createManufacturer: builder.mutation<void, CreateManufacturerBody>({
      query: ({ ...createManufacturerBody }) => ({
        url: ManufacturerApiPaths.ManufacturerAdmin,
        method: "POST",
        body: createManufacturerBody,
      }),
      invalidatesTags: [{ type: "Manufacturers", id: "LIST" }],
    }),

    deleteManufacturer: builder.mutation<boolean, number>({
      query: (manufacturerId) => ({
        url: ManufacturerApiPaths.Manufacturer,
        method: "DELETE",
        params: { id: manufacturerId },
      }),
      invalidatesTags: [{ type: "Manufacturers", id: "LIST" }],
    }),

    updateManufacturer: builder.mutation<void, UpdateManufacturerBody>({
      query: ({ ...updateManufacturerBody }) => ({
        url: ManufacturerApiPaths.Manufacturer,
        method: "PUT",
        body: updateManufacturerBody,
      }),
      invalidatesTags: [{ type: "Manufacturers", id: "LIST" }],
    }),
  }),
});

export const {
  useGetManufacturersQuery,
  useLazyGetManufacturerByIdQuery,
  useCreateManufacturerMutation,
  useDeleteManufacturerMutation,
  useUpdateManufacturerMutation,
} = manufacturerApi;
