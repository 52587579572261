import MainContainer from "@components/MainContainer/MainContainer";
import Typography from "@components/Typography/Typography";
import navigationItems from "@config/navigationItems";
import { Box } from "@mui/material";
import EntitiesNavigationList from "@pages/MainPage/components/EntitiesNavigationList/EntitiesNavigationList";

const MainPage = () => {
  return (
    <MainContainer>
      <Box sx={{ marginBottom: "30px" }}>
        <Typography variant="h5" align="center">
          Изменение данных:
        </Typography>
      </Box>

      <EntitiesNavigationList items={navigationItems} />
    </MainContainer>
  );
};
export default MainPage;
