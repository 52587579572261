import { CreateUpdateSubtitleProps } from "@components/CreateUpdateSubtitle/CreateUpdateSubtitleTypes";
import { StyledCreateUpdateSubtitleButton } from "@components/CreateUpdateSubtitle/StyledCreateUpdateSubtitle";
import Typography from "@components/Typography/Typography";
import { Stack } from "@mui/material";

const CreateUpdateSubtitle = ({
  number,
  subtitle,
}: CreateUpdateSubtitleProps) => {
  return (
    <Stack direction="row" spacing={"15px"} alignItems="center">
      <StyledCreateUpdateSubtitleButton variant="contained" size="small">
        {number}
      </StyledCreateUpdateSubtitleButton>

      <Typography variant="h5">{subtitle}</Typography>
    </Stack>
  );
};
export default CreateUpdateSubtitle;
