import Button from "@components/Button/Button";
import { borderRadius } from "@constants/constants.style";
import { styled } from "@mui/material";

export const StyledAddButton = styled(Button)`
  border-radius: ${borderRadius};
  background-color: rgba(80, 216, 144, 0.15);
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  padding: 15px;
  border: none;

  .MuiButton-endIcon {
    svg {
      font-size: 1.5rem;
    }
  }

  :hover {
    background-color: rgba(80, 216, 144, 0.15);
    border: none;
  }
`;
