import { borderRadius } from "@constants/constants.style";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { Box, styled } from "@mui/material";

export const StyledWithErrorMessageBlock = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  background: ${({ theme }) => theme.palette.secondary.main};
  padding: 20px;
  max-width: ${({ isMobileScreen }) => (isMobileScreen ? "360px" : "385px")};
  width: 100%;
  border-radius: ${borderRadius};
`;
