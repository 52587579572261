import { logout } from "@redux/features/userSlice/userSlice";
import { authApi } from "@redux/services/auth/authApi";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { getTokenFromCookie } from "@utils/cookieToken";
import {
  getRefreshTokenFromCookie,
  setTokenInCookie,
} from "@utils/cookieToken";
import { Mutex } from "async-mutex";

const mutex = new Mutex();

export const baseUrl =
  process.env.NODE_ENV === "development"
    ? "/api"
    : process.env.REACT_APP_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const token = getTokenFromCookie();
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshToken = getRefreshTokenFromCookie();

        const refreshResult = await api
          .dispatch(
            authApi.endpoints.refreshToken.initiate({
              refreshToken,
            })
          )
          .unwrap();

        if (refreshResult) {
          setTokenInCookie(
            refreshResult.accessToken,
            refreshResult.refreshToken
          );
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } catch (error) {
        api.dispatch(logout());
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};
