import {
  AuthRequestBodyServerModel,
  CreateAuthResponseServerModel,
  RefreshTokenBodyServerModel,
} from "@customTypes/api/models/auth.model";
import { AuthApiPaths } from "@redux/services/auth/authApiPaths";
import { baseUrl } from "@redux/services/customBaseQueryWithAuth";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setTokenInCookie } from "@utils/cookieToken";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    authenticate: builder.mutation<
      CreateAuthResponseServerModel,
      AuthRequestBodyServerModel
    >({
      query: (data) => {
        return {
          url: AuthApiPaths.Authenticate,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (res: CreateAuthResponseServerModel) => {
        setTokenInCookie(res.accessToken, res.refreshToken);

        return res;
      },
    }),

    refreshToken: builder.mutation<
      CreateAuthResponseServerModel,
      RefreshTokenBodyServerModel
    >({
      query: (refreshToken) => {
        return {
          url: AuthApiPaths.RefreshToken,
          method: "POST",
          body: refreshToken,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const { useAuthenticateMutation, useRefreshTokenMutation } = authApi;
