import AddIcon from "@mui/icons-material/Add";
import { CreateUpdateProductAddButtonProps } from "@pages/ProductsPage/pages/CreateUpdateProduct/components/CreateUpdateProductAddButton/CreateUpdateProductAddButtonTypes";
import { StyledCreateUpdateProductAddButton } from "@pages/ProductsPage/pages/CreateUpdateProduct/components/CreateUpdateProductAddButton/StyledCreateUpdateProductAddButton";

const CreateUpdateProductAddButton = ({
  className,
  fullWidth = true,
  variant = "outlined",
  ...rest
}: CreateUpdateProductAddButtonProps) => {
  return (
    <StyledCreateUpdateProductAddButton
      variant={variant}
      fullWidth={fullWidth}
      endIcon={<AddIcon />}
      {...rest}
    >
      Добавить
    </StyledCreateUpdateProductAddButton>
  );
};
export default CreateUpdateProductAddButton;
