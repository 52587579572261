import AddButtonLink from "@components/AddButtonLink/AddButtonLink";
import CommonCardEntity from "@components/CommonCardEntity/CommonCardEntity";
import MainContainer from "@components/MainContainer/MainContainer";
import Typography from "@components/Typography/Typography";
import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import { Stack } from "@mui/material";
import {
  useDeleteEmployeeMutation,
  useGetEmployeesQuery,
} from "@redux/services/employee/employeeApi";
import RoutePaths from "@routes/routePaths";

const EmployeesPage = () => {
  // * Api services
  const {
    data: fetchedEmployeeContacts,
    isLoading: isEmployeeContactsLoading,
    isError: isEmployeeContactsError,
  } = useGetEmployeesQuery();

  const [deleteEmployee] = useDeleteEmployeeMutation();

  // * Handlers
  const handleDeleteEmployee = (id: number) => {
    deleteEmployee(id);
  };

  // * Layouts

  const employeeCards = fetchedEmployeeContacts?.map((elem) => {
    return (
      <CommonCardEntity
        key={elem.id}
        id={elem.id}
        title={elem.employerName}
        isDisabledEditButton
        handleDeleteButton={handleDeleteEmployee}
      />
    );
  });

  return (
    <MainContainer sx={{ marginBottom: "100px" }}>
      <Stack
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography variant="h3">Сотрудники</Typography>

        <AddButtonLink
          title="Добавить сотрудника"
          to={RoutePaths.CreateEmployee}
        />
      </Stack>

      <WithLoader isLoading={isEmployeeContactsLoading}>
        <WithErrorMessage
          isError={isEmployeeContactsError}
          stackProps={{
            style: { margin: "200px 0" },
          }}
        >
          <Stack direction="column" sx={{ marginTop: "30px" }} spacing={"60px"}>
            {employeeCards}
          </Stack>
        </WithErrorMessage>
      </WithLoader>
    </MainContainer>
  );
};
export default EmployeesPage;
