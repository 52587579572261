import CategoriesPage from "@pages/CategoriesPage/CategoriesPage";
import CreateUpdateCategory from "@pages/CategoriesPage/pages/CreateUpdateCategory/CreateUpdateCategory";
import ContactsPage from "@pages/ContactsPage/ContactsPage";
import CreateUpdateContact from "@pages/ContactsPage/pages/CreateUpdateContact/CreateUpdateContact";
import EmployeesPage from "@pages/EmployeesPage/EmployeesPage";
import CreateUpdateEmployee from "@pages/EmployeesPage/pages/CreateUpdateEmployee/CreateUpdateEmployee";
import ExtraActionsPage from "@pages/ExtraActionsPage/ExtraActionsPage";
import CreateUpdateExtraAction from "@pages/ExtraActionsPage/pages/CreateUpdateExtraAction/CreateUpdateExtraAction";
import InfoMessagePage from "@pages/InfoMessagePage/InfoMessagePage";
import CreateInfoMessage from "@pages/InfoMessagePage/pages/CreateInfoMessage/CreateInfoMessage";
import MainPage from "@pages/MainPage/MainPage";
import ManufacturersPage from "@pages/ManufacturersPage/ManufacturersPage";
import CreateUpdateManufacturer from "@pages/ManufacturersPage/pages/CreateUpdateManufacturer/CreateUpdateManufacturer";
import NotFoundPage from "@pages/NotFoundPage/NotFoundPage";
import CreateUpdateProduct from "@pages/ProductsPage/pages/CreateUpdateProduct/CreateUpdateProduct";
import ProductsPage from "@pages/ProductsPage/ProductsPage";
import CreateUpdateTare from "@pages/TaresPage/pages/CreateUpdateTare/CreateUpdateTare";
import TaresPage from "@pages/TaresPage/TaresPage";
import RoutePaths from "@routes/routePaths";

const routes = [
  {
    path: RoutePaths.MainPage,
    element: <MainPage />,
  },
  {
    path: RoutePaths.ProductsPage,
    element: <ProductsPage />,
  },
  {
    path: RoutePaths.CreateProduct,
    element: <CreateUpdateProduct />,
  },
  {
    path: RoutePaths.UpdateProduct,
    element: <CreateUpdateProduct />,
  },
  {
    path: RoutePaths.CategoriesPage,
    element: <CategoriesPage />,
  },
  {
    path: RoutePaths.CreateCategory,
    element: <CreateUpdateCategory />,
  },
  {
    path: RoutePaths.UpdateCategory,
    element: <CreateUpdateCategory />,
  },
  {
    path: RoutePaths.ManufacturersPage,
    element: <ManufacturersPage />,
  },
  {
    path: RoutePaths.CreateManufacturer,
    element: <CreateUpdateManufacturer />,
  },
  {
    path: RoutePaths.UpdateManufacturer,
    element: <CreateUpdateManufacturer />,
  },
  {
    path: RoutePaths.EmployeesPage,
    element: <EmployeesPage />,
  },
  {
    path: RoutePaths.CreateEmployee,
    element: <CreateUpdateEmployee />,
  },
  {
    path: RoutePaths.ContactsPage,
    element: <ContactsPage />,
  },
  {
    path: RoutePaths.CreateContact,
    element: <CreateUpdateContact />,
  },
  {
    path: RoutePaths.ExtraActionPage,
    element: <ExtraActionsPage />,
  },
  {
    path: RoutePaths.CreateExtraAction,
    element: <CreateUpdateExtraAction />,
  },
  {
    path: RoutePaths.TaresPage,
    element: <TaresPage />,
  },
  {
    path: RoutePaths.CreateTare,
    element: <CreateUpdateTare />,
  },
  { path: RoutePaths.UpdateTare, element: <CreateUpdateTare /> },
  {
    path: RoutePaths.InfoMessagePage,
    element: <InfoMessagePage />,
  },
  {
    path: RoutePaths.CreateInfoMessage,
    element: <CreateInfoMessage />,
  },
  {
    path: RoutePaths.NotFoundPage,
    element: <NotFoundPage />,
  },
];

export default routes;
