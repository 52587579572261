import { ReactHookFormTextAreaProps } from "@components/form-components/ReactHookFormTextArea/ReactHookFormTextAreaTypes";
import { Controller, useFormContext } from "react-hook-form";

const ReactHookFormTextArea = ({
  className,
  name,
  defaultValue,
  rules,
  ...restProps
}: ReactHookFormTextAreaProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ ...rules }}
      render={({ field }) => {
        return <textarea className={className} {...field} {...restProps} />;
      }}
    />
  );
};
export default ReactHookFormTextArea;
