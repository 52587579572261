import { TypographyProps } from "@components/Typography/TypographyTypes";
import theme from "@config/theme";
import MuiTypography from "@mui/material/Typography";

const Typography = ({
  children,
  className,
  color = theme.palette.text.primary,
  ...rest
}: TypographyProps) => {
  return (
    <MuiTypography className={className} color={color} {...rest}>
      {children}
    </MuiTypography>
  );
};

export default Typography;
