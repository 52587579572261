import { useCallback, useState } from "react";

import AddButtonLink from "@components/AddButtonLink/AddButtonLink";
import MainContainer from "@components/MainContainer/MainContainer";
import PaginationControlled from "@components/PaginationControlled/PaginationControlled";
import Typography from "@components/Typography/Typography";
import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import { Box, Stack } from "@mui/material";
import ProductCard from "@pages/ProductsPage/components/ProductCard/ProductCard";
import {
  useGetAcidsOnPageQuery,
  useDeleteAcidMutation,
} from "@redux/services/acid/acidApi";
import RoutePaths from "@routes/routePaths";

const ProductsPage = () => {
  //  * Local state
  const [currentPage, setCurrentPage] = useState(0);

  // * Api services
  const {
    data: fetchedAcids,
    isLoading: isAcidsLoading,
    isError: isAcidsError,
  } = useGetAcidsOnPageQuery({
    page: currentPage,
    size: 5,
  });

  const [deleteAcid] = useDeleteAcidMutation();

  // * Handlers
  const handleDeleteProduct = useCallback(
    (id: number) => {
      deleteAcid(id);
    },
    [deleteAcid]
  );

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value - 1);
  };

  // * Layouts
  const acidsGoodCards = fetchedAcids?.content.map((elem) => {
    const acidFile =
      !elem.acidFiles || elem.acidFiles.length === 0
        ? null
        : elem.acidFiles[0].name;

    return (
      <ProductCard
        id={elem.id}
        key={elem.id}
        image={acidFile}
        name={elem.name}
        prices={elem.prices}
        categoryName={elem.category?.productCategories}
        manufacturerName={elem.manufacturer?.name}
        count={elem.count}
        handleDeleteButton={handleDeleteProduct}
      />
    );
  });

  return (
    <MainContainer sx={{ marginBottom: "100px" }}>
      <Stack
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography variant="h3">Товары</Typography>

        <AddButtonLink title="Добавить товар" to={RoutePaths.CreateProduct} />
      </Stack>

      <WithLoader isLoading={isAcidsLoading}>
        <WithErrorMessage
          isError={isAcidsError}
          stackProps={{
            style: { margin: "200px 0" },
          }}
        >
          <Stack direction="column" sx={{ marginTop: "30px" }} spacing={"60px"}>
            {acidsGoodCards}
          </Stack>
          {fetchedAcids && (
            <Box sx={{ marginTop: "60px" }}>
              <PaginationControlled
                wrapperStackProps={{ spacing: "30px", alignItems: "end" }}
                count={fetchedAcids.totalPages}
                // ?NOTE: currentPage + 1, because the data is returned from page 0, it is needed to sync pages
                page={currentPage + 1}
                onChange={handlePaginationChange}
              />
            </Box>
          )}
        </WithErrorMessage>
      </WithLoader>
    </MainContainer>
  );
};

export default ProductsPage;
