import Button from "@components/Button/Button";
import { StyledSubmitButtonProps } from "@components/StyledSubmitButton/StyledSubmitButtonTypes";
import { borderRadius } from "@constants/constants.style";
import { styled } from "@mui/material";

const StyledButton = styled(Button)`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  padding: 14px 0;
  border-radius: ${borderRadius};
`;

const StyledSubmitButton = ({
  className,
  variant = "contained",
  title = "Отправить",
  fullWidth = true,
  ...rest
}: StyledSubmitButtonProps) => {
  return (
    <StyledButton
      variant={variant}
      type="submit"
      fullWidth={fullWidth}
      {...rest}
    >
      {title}
    </StyledButton>
  );
};
export default StyledSubmitButton;
