import { useEffect } from "react";

import MainContainer from "@components/MainContainer/MainContainer";
import StyledReactHookFormInput from "@components/StyledReactHookFormInput/StyledReactHookFormInput";
import StyledSubmitButton from "@components/StyledSubmitButton/StyledSubmitButton";
import { Stack } from "@mui/material";
import { StyledLoginPageForm } from "@pages/LoginPage/StyledLoginPage";
import { useAuthenticateMutation } from "@redux/services/auth/authApi";
import RoutePaths from "@routes/routePaths";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

type LoginFormValues = {
  username: string;
  password: string;
};

const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const methods = useForm<LoginFormValues>({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const { handleSubmit } = methods;

  // * Api services
  const [authenticate, { isSuccess }] = useAuthenticateMutation();

  // * Side effects
  useEffect(() => {
    if (!isSuccess) return;

    if (location.state.from.pathname) {
      navigate(location.state.from.pathname);
      return;
    }

    navigate(RoutePaths.MainPage);
  }, [isSuccess, location.state, navigate]);

  const handleSubmitForm = async (data: LoginFormValues) => {
    await authenticate(data);
  };

  return (
    <MainContainer>
      <FormProvider {...methods}>
        <StyledLoginPageForm onSubmit={handleSubmit(handleSubmitForm)}>
          <Stack direction="column" spacing={"30px"}>
            <StyledReactHookFormInput
              name="username"
              autoComplete="username"
              placeholder="Логин"
              rules={{
                required: "Поле обязательно для заполнения.",
              }}
            />

            <StyledReactHookFormInput
              name="password"
              type="password"
              placeholder="Пароль"
              autoComplete="current-password"
              rules={{
                required: "Поле обязательно для заполнения.",
              }}
            />

            <StyledSubmitButton
              title="Войти"
              fullWidth
              sx={{ fontSize: "18px" }}
            />
          </Stack>
        </StyledLoginPageForm>
      </FormProvider>
    </MainContainer>
  );
};
export default LoginPage;
