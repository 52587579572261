import ReactHookFormTextArea from "@components/form-components/ReactHookFormTextArea/ReactHookFormTextArea";
import { borderRadius } from "@constants/constants.style";
import { styled } from "@mui/material";

export const StyledCreateUpdateProductMainInfo = styled("div")`
  margin-top: 90px;
`;

export const StyledTextArea = styled(ReactHookFormTextArea)`
  width: 100%;
  height: 120px;
  border-radius: ${borderRadius};
  padding: 16px;
  resize: none;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: ${({ theme }) => theme.palette.text.primary};
  border: 1px solid rgba(0, 0, 0, 0.23);

  :hover {
    border: 1px solid ${({ theme }) => theme.palette.text.primary};
  }

  :focus-visible {
    outline: 2px solid ${({ theme }) => theme.palette.primary.light};
  }
`;
