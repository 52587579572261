import CreateUpdateSubtitle from "@components/CreateUpdateSubtitle/CreateUpdateSubtitle";
import ReactHookFormInput from "@components/form-components/ReactHookFormInput/ReactHookFormInput";
import IconButton from "@components/IconButton/IconButton";
import StyledReactHookFormInput from "@components/StyledReactHookFormInput/StyledReactHookFormInput";
import Typography from "@components/Typography/Typography";
import theme from "@config/theme";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { Box, Stack } from "@mui/material";
import CreateUpdateProductAddButton from "@pages/ProductsPage/pages/CreateUpdateProduct/components/CreateUpdateProductAddButton/CreateUpdateProductAddButton";
import { useFieldArray, useFormContext } from "react-hook-form";

const CreateUpdateProductCertificates = () => {
  const { control, watch } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "certificates",
  });

  const handleAddField = () => {
    append({ frontName: "", file: null });
  };

  const certificates = watch("certificates");

  const certificateName = (index: number) => {
    if (
      certificates[index] &&
      certificates[index].file &&
      certificates[index].file[0]
    ) {
      return (
        <Typography variant="body1" color="primary.main">
          {certificates[index].file[0].name}
        </Typography>
      );
    }

    if (certificates[index].name) {
      const formattedName = certificates[index].name
        .split(".")
        .slice(-2)
        .join(".");
      return (
        <Typography variant="body1" color="primary.main">
          {formattedName}
        </Typography>
      );
    }
  };

  const certificatesFields = fields.map((item, index) => {
    return (
      <Stack direction="row" spacing={"15px"} alignItems="center" key={item.id}>
        <StyledReactHookFormInput
          name={`certificates.${index}.frontName`}
          maxWidth="600px"
          rules={{
            required: "Поле обязательно для заполнения.",
          }}
          fullWidth
        />

        {certificateName(index) ? (
          certificateName(index)
        ) : (
          <ReactHookFormInput
            name={`certificates.${index}.file`}
            type="file"
            rules={{
              required: "Выберите файл.",
            }}
            inputProps={{
              accept:
                ".doc,.docx,.txt,.pdf,.xml,.png,.jpg,.ttf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            }}
            label={
              <IconButton
                sx={{ zIndex: "-1" }}
                icon={<NoteAddOutlinedIcon color="primary" />}
                backgroundColor="rgba(26, 115, 232, 0.15);"
              />
            }
          />
        )}

        <IconButton
          onClick={() => remove(index)}
          icon={<DeleteIcon htmlColor={theme.palette.error.light} />}
        />
      </Stack>
    );
  });

  return (
    <div>
      <CreateUpdateSubtitle number="4" subtitle="Документация" />

      <Box sx={{ marginTop: "60px" }}>
        <Stack direction="column" spacing={"30px"}>
          {certificatesFields}
          <CreateUpdateProductAddButton onClick={handleAddField} />
        </Stack>
      </Box>
    </div>
  );
};
export default CreateUpdateProductCertificates;
