import { WithLoaderProps } from "@components/WithLoader/WithLoaderTypes";
import { Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const WithLoader = ({
  className,
  children,
  isLoading,
  loaderProps,
  stackProps,
}: WithLoaderProps) => {
  if (isLoading) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        {...stackProps}
      >
        <CircularProgress className={className} {...loaderProps} />
      </Stack>
    );
  }

  return <>{children}</>;
};
export default WithLoader;
