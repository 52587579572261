import CreateUpdateSubtitle from "@components/CreateUpdateSubtitle/CreateUpdateSubtitle";
import MainContainer from "@components/MainContainer/MainContainer";
import SelectWithStyledOptions from "@components/SelectWithStyledOptions/SelectWithStyledOptions";
import StyledReactHookFormInput from "@components/StyledReactHookFormInput/StyledReactHookFormInput";
import StyledSubmitButton from "@components/StyledSubmitButton/StyledSubmitButton";
import Typography from "@components/Typography/Typography";
import { CURRENCY_SELECT_OPTIONS } from "@constants/constants";
import { Box, Stack } from "@mui/material";
import { ExtraActionFormValues } from "@pages/ExtraActionsPage/pages/CreateUpdateExtraAction/CreateUpdateExtraActionTypes";
import { useCreateExtraActionMutation } from "@redux/services/extraActions/extraActionApi";
import { useForm, FormProvider } from "react-hook-form";

const CreateUpdateExtraAction = () => {
  //  * Libraries
  const methods = useForm<ExtraActionFormValues>({
    defaultValues: {
      name: "",
      description: "",
      price: { price: "", currency: "RUB", count: 0 },
    },
  });

  const { handleSubmit, reset } = methods;

  // * Api services
  const [createExtraAction] = useCreateExtraActionMutation();

  // * Handlers
  const handleCreateExtraAction = (formData: ExtraActionFormValues) => {
    const extraActionPrice = {
      ...formData.price,
      price: formData.price.price ? Number(formData.price.price) : null,
    };

    const creatingExtraActionBody = {
      ...formData,
      price: extraActionPrice,
    };

    createExtraAction(creatingExtraActionBody);

    reset();
  };

  const handleSubmitForm = (data: ExtraActionFormValues) => {
    handleCreateExtraAction(data);
  };

  // * Layouts
  return (
    <MainContainer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box sx={{ marginTop: "90px" }}>
            <Stack direction="column" spacing={"60px"}>
              <div>
                <CreateUpdateSubtitle
                  number="1"
                  subtitle="Основная информация"
                />
                <Box sx={{ marginTop: "60px" }}>
                  <Stack direction="column" spacing={"30px"}>
                    <Stack direction="column" spacing={"15px"}>
                      <Typography variant="body1" fontWeight="fontWeightBold">
                        Название действия:
                      </Typography>

                      <StyledReactHookFormInput
                        name="name"
                        rules={{
                          required: "Поле обязательно для заполнения.",
                        }}
                      />
                    </Stack>

                    <Stack direction="column" spacing={"15px"}>
                      <Typography variant="body1" fontWeight="fontWeightBold">
                        Описание действия:
                      </Typography>

                      <StyledReactHookFormInput name="description" />
                    </Stack>
                  </Stack>
                </Box>
              </div>

              <div>
                <CreateUpdateSubtitle number="2" subtitle="Цена действия" />
                <Box sx={{ marginTop: "60px" }}>
                  <Stack direction="column" spacing={"30px"}>
                    <Stack direction="column" spacing={"15px"}>
                      <Typography variant="body1" fontWeight="fontWeightBold">
                        Цена за одну штуку:
                      </Typography>

                      <Stack direction="row" spacing={"15px"}>
                        <StyledReactHookFormInput
                          name="price.price"
                          isNumberInput
                        />

                        <SelectWithStyledOptions
                          name="price.currency"
                          options={CURRENCY_SELECT_OPTIONS}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              </div>
            </Stack>
          </Box>
          <Box sx={{ marginTop: "60px", marginBottom: "120px" }}>
            <StyledSubmitButton />
          </Box>
        </form>
      </FormProvider>
    </MainContainer>
  );
};
export default CreateUpdateExtraAction;
