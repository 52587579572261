import ReactHookFormSelect from "@components/form-components/ReactHookFormSelect/ReactHookFormSelect";
import { borderRadius } from "@constants/constants.style";
import { MenuItem, styled } from "@mui/material";

export const StyledReactHookFormSelect = styled(ReactHookFormSelect)`
  .MuiSelect-select {
    display: flex;
    align-items: center;
  }
  .MuiInputBase-root {
    border-radius: ${borderRadius};
  }
`;

export const StyledSelectItem = styled(MenuItem)`
  padding: 15px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(39, 39, 39, 0.05);
  :last-child {
    border-bottom: none;
  }
`;
