import RoutePaths from "@routes/routePaths";

export type navigationItemType = {
  title: string;
  to: string;
};

const navigationItems: navigationItemType[] = [
  {
    title: "Товары",
    to: RoutePaths.ProductsPage,
  },
  {
    title: "Категории",
    to: RoutePaths.CategoriesPage,
  },
  {
    title: "Производители",
    to: RoutePaths.ManufacturersPage,
  },
  {
    title: "Сотрудники",
    to: RoutePaths.EmployeesPage,
  },
  {
    title: "Контакты",
    to: RoutePaths.ContactsPage,
  },
  {
    title: "Доп. действия (заявка)",
    to: RoutePaths.ExtraActionPage,
  },
  {
    title: "Тара (заявка)",
    to: RoutePaths.TaresPage,
  },
  {
    title: "Popup сообщение",
    to: RoutePaths.InfoMessagePage,
  },
];

export default navigationItems;
