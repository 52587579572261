import { AnimatedNotFoundPageComponentProps } from "@pages/NotFoundPage/components/AnimatedNotFoundPageComponent/AnimatedNotFoundPageComponentTypes";
import { AnimateKeyframes } from "react-simple-animate";

const AnimatedNotFoundPageComponent = ({
  children,
  keyframes,
  delay,
}: AnimatedNotFoundPageComponentProps) => {
  return (
    <AnimateKeyframes
      play
      delay={delay}
      duration={1}
      iterationCount="infinite"
      direction="alternate"
      keyframes={keyframes}
    >
      {children}
    </AnimateKeyframes>
  );
};
export default AnimatedNotFoundPageComponent;
