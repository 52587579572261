import { borderRadius } from "@constants/constants.style";
import { Box, styled } from "@mui/material";

export const StyledProductCard = styled(Box)`
  width: 100%;
`;

export const StyledProductCardUpBoxBlock = styled(Box)`
  padding: 20px;
  background: ${({ theme }) => theme.palette.common.white};
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
`;

export const StyledProductCardBottomBoxBlock = styled(Box)`
  background: rgba(26, 115, 232, 0.05);
  padding: 30px;
  border-bottom-left-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
`;

export const StyledProductCardImageBoxWrapper = styled(Box)`
  max-width: 100px;
`;

export const StyledProductCardImage = styled("img")`
  width: 100%;
  height: 100%;
  border-radius: ${borderRadius};
`;
