import { AddButtonLinkProps } from "@components/AddButtonLink/AddButtonLinkTypes";
import { StyledAddButton } from "@components/AddButtonLink/StyledAddButtonLink";
import RouterLink from "@components/RouterLink/RouterLink";
import AddIcon from "@mui/icons-material/Add";

const AddButtonLink = ({
  title,
  className,
  to,
  ...restButtonProps
}: AddButtonLinkProps) => {
  return (
    <RouterLink to={to}>
      <StyledAddButton
        className={className}
        variant="outlined"
        color="success"
        endIcon={<AddIcon />}
        {...restButtonProps}
      >
        {title}
      </StyledAddButton>
    </RouterLink>
  );
};
export default AddButtonLink;
