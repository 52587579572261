import {
  GetDataWithPagination,
  PaginationParams,
} from "@customTypes/api/general";
import {
  CreateContactBody,
  GetContactsAllApiResponse,
} from "@customTypes/api/models/contact.model";
import { ContactApiPaths } from "@redux/services/contact/contactApiPaths";
import generalApi from "@redux/services/initialApi";

export const contactApi = generalApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getContactsOnPage: builder.query<
        GetDataWithPagination<GetContactsAllApiResponse>,
        PaginationParams
      >({
        query: (paginationParams) => ({
          url: ContactApiPaths.ContactAllOnPage,
          params: { ...paginationParams },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.content.map(({ id }) => ({
                  type: "Contacts" as const,
                  id,
                })),
                { type: "Contacts", id: "LIST" },
              ]
            : [{ type: "Contacts", id: "LIST" }],
      }),

      createContact: builder.mutation<void, CreateContactBody>({
        query: ({ ...creatingContactBody }) => ({
          url: ContactApiPaths.ContactAdminContact,
          method: "POST",
          body: creatingContactBody,
        }),
        invalidatesTags: [{ type: "Contacts", id: "LIST" }],
      }),

      deleteContact: builder.mutation<void, number>({
        query: (contactId) => ({
          url: ContactApiPaths.ContactAdminContact,
          method: "DELETE",
          params: { id: contactId },
        }),
        invalidatesTags: [{ type: "Contacts", id: "LIST" }],
      }),
    };
  },
});

export const {
  useGetContactsOnPageQuery,
  useCreateContactMutation,
  useDeleteContactMutation,
} = contactApi;
