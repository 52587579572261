import errorImage from "@assets/images/imageError.png";
import { CommonCardEntityProps } from "@components/CommonCardEntity/CommonCardEntityTypes";
import {
  StyledCommonCardEntityBoxContainer,
  StyledCommonCardEntityImageBoxWrapper,
  StyledCommonCardEntityImage,
  StyledRouterLink,
} from "@components/CommonCardEntity/StyledCommonCardEntity";
import IconButton from "@components/IconButton/IconButton";
import Typography from "@components/Typography/Typography";
import theme from "@config/theme";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import generateFileSrc from "@utils/generateFileSrc";
import imageOnErrorHandler from "@utils/handleImageError";

const CommonCardEntity = ({
  image,
  id,
  title,
  linkToEditRoute,
  handleDeleteButton,
  isDisabledDeleteButton = false,
  isDisabledEditButton = false,
}: CommonCardEntityProps) => {
  // * Layouts
  const editableButtonLinkContent = () => {
    if (!linkToEditRoute) {
      return (
        <IconButton
          icon={
            <EditOutlinedIcon
              color={isDisabledEditButton ? "disabled" : "warning"}
            />
          }
          backgroundColor="rgba(251, 188, 4, 0.25)"
          disabled={isDisabledEditButton}
        />
      );
    }

    return (
      <StyledRouterLink
        to={linkToEditRoute}
        isDisabledEditButton={isDisabledEditButton}
      >
        <IconButton
          icon={
            <EditOutlinedIcon
              color={isDisabledEditButton ? "disabled" : "warning"}
            />
          }
          backgroundColor="rgba(251, 188, 4, 0.25)"
          disabled={isDisabledEditButton}
        />
      </StyledRouterLink>
    );
  };

  return (
    <StyledCommonCardEntityBoxContainer>
      <Grid2 container justifyContent="space-between" alignItems="center">
        <Grid2 container alignItems="center" spacing={"30px"} lg={5}>
          {image && (
            <Grid2>
              <StyledCommonCardEntityImageBoxWrapper>
                <StyledCommonCardEntityImage
                  src={image ? generateFileSrc(image) : errorImage}
                  alt={title}
                  onError={(event) => imageOnErrorHandler(event)}
                />
              </StyledCommonCardEntityImageBoxWrapper>
            </Grid2>
          )}

          <Grid2>
            <Typography variant="h5">{title}</Typography>
          </Grid2>
        </Grid2>

        <Grid2>
          <Stack direction="row" spacing={"15px"}>
            {editableButtonLinkContent()}

            <IconButton
              onClick={() => handleDeleteButton(id)}
              icon={
                <DeleteIcon
                  color={isDisabledDeleteButton ? "disabled" : "inherit"}
                  htmlColor={theme.palette.error.light}
                />
              }
              disabled={isDisabledDeleteButton}
            />
          </Stack>
        </Grid2>
      </Grid2>
    </StyledCommonCardEntityBoxContainer>
  );
};
export default CommonCardEntity;
