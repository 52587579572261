import ReactHookFormInput from "@components/form-components/ReactHookFormInput/ReactHookFormInput";
import { borderRadius } from "@constants/constants.style";
import { Box, styled } from "@mui/material";

export const StyledCreateUpdateImageBlockReactHookFormInput = styled(
  ReactHookFormInput
)`
  width: 100%;
  max-width: 200px;
`;

export const StyledInputFileLabel = styled(Box)`
  text-align: center;
  border-radius: ${borderRadius};
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(26, 115, 232, 0.05);
`;

export const StyledPreviewImageContainer = styled("div")`
  max-height: 200px;
  max-width: 200px;
  display: flex;
  align-items: center;
`;

export const StyledPreviewImage = styled("img")`
  width: 100%;
  border-radius: ${borderRadius};
  height: 100%;
`;
