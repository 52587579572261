import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "./customBaseQueryWithAuth";

const generalApi = createApi({
  tagTypes: [
    "Acids",
    "Categories",
    "Manufacturers",
    "Employees",
    "Contacts",
    "ExtraActions",
    "Tares",
    "InfoMessages",
  ],
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
export default generalApi;
