import { useCallback, useEffect } from "react";

import { useRefreshTokenMutation } from "@redux/services/auth/authApi";
import RoutePaths from "@routes/routePaths";
import {
  getRefreshTokenFromCookie,
  getTokenFromCookie,
  tokenValid,
} from "@utils/cookieToken";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

const RequireUser = () => {
  const token: string | null = getTokenFromCookie();
  const refreshToken: string | null = getRefreshTokenFromCookie();
  const location = useLocation();
  const navigate = useNavigate();

  const [updateAccessToken, { isError }] = useRefreshTokenMutation();

  const isRefreshTokenValid = refreshToken ? tokenValid(refreshToken) : false;
  const isTokenValid = token ? tokenValid(token) : false;

  const updateToken = useCallback(
    async (refreshToken: string) => {
      await updateAccessToken({ refreshToken });
    },
    [updateAccessToken]
  );

  useEffect(() => {
    if (!isRefreshTokenValid || isTokenValid || !refreshToken) return;

    updateToken(refreshToken);
  }, [isRefreshTokenValid, isTokenValid, refreshToken, updateToken]);

  useEffect(() => {
    if (!isError) return;
    navigate(RoutePaths.LoginPage);
  }, [isError, navigate]);

  if (!token || !isRefreshTokenValid) {
    return (
      <Navigate to={RoutePaths.LoginPage} state={{ from: location }} replace />
    );
  }

  return <Outlet />;
};
export default RequireUser;
