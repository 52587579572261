import React from "react";

import CreateUpdateSubtitle from "@components/CreateUpdateSubtitle/CreateUpdateSubtitle";
import IconButton from "@components/IconButton/IconButton";
import StyledReactHookFormInput from "@components/StyledReactHookFormInput/StyledReactHookFormInput";
import theme from "@config/theme";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CreateUpdateProductAddButton from "@pages/ProductsPage/pages/CreateUpdateProduct/components/CreateUpdateProductAddButton/CreateUpdateProductAddButton";
import { useFieldArray, useFormContext } from "react-hook-form";

type CreateUpdateGoodCharacteristicsProps = {
  className?: string;
};

const CreateUpdateGoodCharacteristics = ({
  className,
}: CreateUpdateGoodCharacteristicsProps) => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "characteristics",
  });

  const characteristicsFields = fields.map((item, index) => (
    <Grid2
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      columnSpacing={"15px"}
      key={item.id}
      xs
    >
      <Grid2 lg={6}>
        <StyledReactHookFormInput
          name={`characteristics.${index}.name`}
          fullWidth
        />
      </Grid2>

      <Grid2 lg>
        <StyledReactHookFormInput
          name={`characteristics.${index}.value`}
          fullWidth
        />
      </Grid2>

      <Grid2 lg>
        <StyledReactHookFormInput
          name={`characteristics.${index}.description`}
          fullWidth
        />
      </Grid2>

      <Grid2>
        <IconButton
          icon={<DeleteIcon htmlColor={theme.palette.error.light} />}
          onClick={() => remove(index)}
        />
      </Grid2>
    </Grid2>
  ));

  return (
    <div className={className}>
      <CreateUpdateSubtitle number="3" subtitle="Характеристики" />

      <Box sx={{ marginTop: "60px" }}>
        <Grid2 container direction="column" rowSpacing={"30px"}>
          {characteristicsFields}

          <Grid2>
            <CreateUpdateProductAddButton
              onClick={() =>
                append({
                  name: "",
                  value: "",
                  description: "",
                })
              }
            />
          </Grid2>
        </Grid2>
      </Box>
    </div>
  );
};
export default CreateUpdateGoodCharacteristics;
