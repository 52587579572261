import AddButtonLink from "@components/AddButtonLink/AddButtonLink";
import CommonCardEntity from "@components/CommonCardEntity/CommonCardEntity";
import MainContainer from "@components/MainContainer/MainContainer";
import Typography from "@components/Typography/Typography";
import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import { Stack } from "@mui/material";
import {
  useDeleteExtraActionMutation,
  useGetExtraActionsQuery,
} from "@redux/services/extraActions/extraActionApi";
import RoutePaths from "@routes/routePaths";

const ExtraActionsPage = () => {
  // * Api services
  const {
    data: fetchedExtraActions,
    isLoading: isExtraActionsLoading,
    isError: isExtraActionsError,
  } = useGetExtraActionsQuery();

  const [deleteExtraAction] = useDeleteExtraActionMutation();

  // * Handlers
  const handleDeleteExtraAction = (extraActionId: number) => {
    deleteExtraAction(extraActionId);
  };

  // * Layouts
  const extraActionCards = fetchedExtraActions?.map((elem) => {
    return (
      <CommonCardEntity
        key={elem.id}
        id={elem.id}
        title={elem.name}
        isDisabledEditButton
        handleDeleteButton={handleDeleteExtraAction}
      />
    );
  });

  return (
    <MainContainer sx={{ marginBottom: "100px" }}>
      <Stack
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography variant="h3">Дополнительные действия (заявка)</Typography>

        <AddButtonLink
          title="Добавить действие"
          to={RoutePaths.CreateExtraAction}
        />
      </Stack>

      <WithLoader isLoading={isExtraActionsLoading}>
        <WithErrorMessage
          isError={isExtraActionsError}
          stackProps={{
            style: { margin: "200px 0" },
          }}
        >
          <Stack direction="column" sx={{ marginTop: "30px" }} spacing={"60px"}>
            {extraActionCards}
          </Stack>
        </WithErrorMessage>
      </WithLoader>
    </MainContainer>
  );
};
export default ExtraActionsPage;
