import React from "react";

import Header from "@components/Header/Header";
import RequireUser from "@components/RequireUser/RequireUser";
import theme from "@config/theme";
import { GlobalStyles } from "@mui/material";
import LoginPage from "@pages/LoginPage/LoginPage";
import RoutePaths from "@routes/routePaths";
import routes from "@routes/routes";
import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";

const pages = routes.map((route) => (
  <Route key={route.path} element={<RequireUser />}>
    <Route path={route.path} element={route.element} />
  </Route>
));

const globalStyles = `
  body {
    background: ${theme.palette.background.default};
    margin: 0px;
  };
  * {
    box-sizing: border-box;
  }
`;

function App() {
  return (
    <div>
      <GlobalStyles styles={globalStyles} />
      <Header />
      <Routes>
        <Route path={RoutePaths.LoginPage} element={<LoginPage />} />
        {pages}
      </Routes>
      <Toaster />
    </div>
  );
}

export default App;
