import notFound1 from "@assets/images/notFound1.png";
import notFound2 from "@assets/images/notFound2.png";
import notFound3 from "@assets/images/notFound3.png";
import notFound4 from "@assets/images/notFound4.png";
import notFound5 from "@assets/images/notFound5.png";
import notFound6 from "@assets/images/notFound6.png";
import Typography from "@components/Typography/Typography";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import AnimatedNotFoundPageComponent from "@pages/NotFoundPage/components/AnimatedNotFoundPageComponent/AnimatedNotFoundPageComponent";
import {
  StyledNotFoundPageLink,
  StyledNotFoundPageImage,
  StyledNotFoundImage1Wrapper,
  StyledNotFoundImage2Wrapper,
  StyledNotFoundImage3Wrapper,
  StyledNotFoundImage4Wrapper,
  StyledNotFoundImage5Wrapper,
  StyledNotFoundImage6Wrapper,
  StyledNotFoundPageMainContainer,
} from "@pages/NotFoundPage/StyledNotFoundPage";
import RoutePaths from "@routes/routePaths";

const NotFoundPage = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTableScreen = useMediaQuery(theme.breakpoints.down("md"));

  const notFondPageTitle = "Такой страницы нет,\n вернуться на ";

  return (
    <StyledNotFoundPageMainContainer isMobileScreen={isMobileScreen}>
      <Stack direction="column">
        <Typography
          fontSize={isMobileScreen ? "128px" : "256px"}
          color={theme.palette.primary.main}
          fontWeight={theme.typography.fontWeightBold}
          lineHeight={isMobileScreen ? "150px" : "300px"}
        >
          404
        </Typography>
        <Typography
          variant={isMobileScreen ? "h5" : "h4"}
          fontWeight={theme.typography.fontWeightRegular}
          sx={{ whiteSpace: "pre-line" }}
        >
          {notFondPageTitle}
          <StyledNotFoundPageLink
            to={RoutePaths.MainPage}
            color={theme.palette.primary.main}
            underline={true}
          >
            главную...
          </StyledNotFoundPageLink>
        </Typography>
      </Stack>

      <StyledNotFoundImage1Wrapper
        isMobileScreen={isMobileScreen}
        isTableScreen={isTableScreen}
      >
        <AnimatedNotFoundPageComponent
          keyframes={[
            "transform: translateY(0)",
            "transform: translateY(12px)",
          ]}
        >
          <StyledNotFoundPageImage src={notFound1} alt="Плоскодонная колба" />
        </AnimatedNotFoundPageComponent>
      </StyledNotFoundImage1Wrapper>

      <StyledNotFoundImage2Wrapper
        isMobileScreen={isMobileScreen}
        isTableScreen={isTableScreen}
      >
        <AnimatedNotFoundPageComponent
          keyframes={[
            "transform: translateY(-12px)",
            "transform: translateY(0)",
          ]}
          delay={1}
        >
          <StyledNotFoundPageImage src={notFound2} alt="Пластиковый еврокуб" />
        </AnimatedNotFoundPageComponent>
      </StyledNotFoundImage2Wrapper>

      <StyledNotFoundImage3Wrapper
        isMobileScreen={isMobileScreen}
        isTableScreen={isTableScreen}
      >
        <AnimatedNotFoundPageComponent
          keyframes={[
            "transform: translateY(0)",
            "transform: translateY(12px)",
          ]}
        >
          <StyledNotFoundPageImage src={notFound3} alt="Мерный цилиндр" />
        </AnimatedNotFoundPageComponent>
      </StyledNotFoundImage3Wrapper>

      <StyledNotFoundImage4Wrapper
        isMobileScreen={isMobileScreen}
        isTableScreen={isTableScreen}
      >
        <AnimatedNotFoundPageComponent
          keyframes={[
            "transform: translateY(-12px)",
            "transform: translateY(0)",
          ]}
          delay={1}
        >
          <StyledNotFoundPageImage src={notFound4} alt="3D Лого" />
        </AnimatedNotFoundPageComponent>
      </StyledNotFoundImage4Wrapper>

      <StyledNotFoundImage5Wrapper
        isMobileScreen={isMobileScreen}
        isTableScreen={isTableScreen}
      >
        <AnimatedNotFoundPageComponent
          keyframes={[
            "transform: translateY(0)",
            "transform: translateY(12px)",
          ]}
        >
          <StyledNotFoundPageImage src={notFound5} alt="Лабораторный стакан" />
        </AnimatedNotFoundPageComponent>
      </StyledNotFoundImage5Wrapper>

      <StyledNotFoundImage6Wrapper
        isMobileScreen={isMobileScreen}
        isTableScreen={isTableScreen}
      >
        <AnimatedNotFoundPageComponent
          keyframes={[
            "transform: translateY(-12px)",
            "transform: translateY(0)",
          ]}
          delay={1}
        >
          <StyledNotFoundPageImage src={notFound6} alt="Пластиковая тара" />
        </AnimatedNotFoundPageComponent>
      </StyledNotFoundImage6Wrapper>
    </StyledNotFoundPageMainContainer>
  );
};
export default NotFoundPage;
