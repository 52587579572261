import { useCallback, useState } from "react";

import AddButtonLink from "@components/AddButtonLink/AddButtonLink";
import CommonCardEntity from "@components/CommonCardEntity/CommonCardEntity";
import MainContainer from "@components/MainContainer/MainContainer";
import PaginationControlled from "@components/PaginationControlled/PaginationControlled";
import Typography from "@components/Typography/Typography";
import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import { Box, Stack } from "@mui/material";
import {
  useDeleteCategoryMutation,
  useGetCategoriesAllOnPageQuery,
} from "@redux/services/category/categoryApi";
import RoutePaths from "@routes/routePaths";

const CategoriesPage = () => {
  //  * Local state
  const [currentPage, setCurrentPage] = useState(0);

  // * Api services
  const {
    data: fetchedCategories,
    isError: isFetchedCategoriesError,
    isLoading: isFetchedCategoriesLoading,
  } = useGetCategoriesAllOnPageQuery({
    page: currentPage,
    size: 5,
  });

  const [deleteCategory] = useDeleteCategoryMutation();

  // * Handlers
  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value - 1);
  };

  const handleDeleteCategory = useCallback(
    (id: number) => {
      deleteCategory(id);
    },
    [deleteCategory]
  );

  // * Layouts
  const categoriesCards = fetchedCategories?.content.map((elem) => {
    const categoryFileName = !elem.file ? null : elem.file.name;

    return (
      <CommonCardEntity
        key={elem.id}
        id={elem.id}
        linkToEditRoute={`/categories/${String(elem.id)}/edit`}
        title={elem.productCategories}
        image={categoryFileName}
        handleDeleteButton={handleDeleteCategory}
      />
    );
  });

  return (
    <MainContainer sx={{ marginBottom: "100px" }}>
      <Stack
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography variant="h3">Категории</Typography>

        <AddButtonLink
          title="Добавить категорию"
          to={RoutePaths.CreateCategory}
        />
      </Stack>

      <WithLoader isLoading={isFetchedCategoriesLoading}>
        <WithErrorMessage
          isError={isFetchedCategoriesError}
          stackProps={{
            style: { margin: "200px 0" },
          }}
        >
          <Stack direction="column" sx={{ marginTop: "30px" }} spacing={"60px"}>
            {categoriesCards}
          </Stack>
          {fetchedCategories && (
            <Box sx={{ marginTop: "60px" }}>
              <PaginationControlled
                wrapperStackProps={{ spacing: "30px", alignItems: "end" }}
                count={fetchedCategories.totalPages}
                // ?NOTE: currentPage + 1, because the data is returned from page 0, it is needed to sync pages
                page={currentPage + 1}
                onChange={handlePaginationChange}
              />
            </Box>
          )}
        </WithErrorMessage>
      </WithLoader>
    </MainContainer>
  );
};
export default CategoriesPage;
