import errorImage from "@assets/images/imageError.png";
import IconButton from "@components/IconButton/IconButton";
import PriceBlock from "@components/PriceBlock/PriceBlock";
import RouterLink from "@components/RouterLink/RouterLink";
import Typography from "@components/Typography/Typography";
import theme from "@config/theme";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ProductCardProps } from "@pages/ProductsPage/components/ProductCard/ProductCardTypes";
import {
  StyledProductCard,
  StyledProductCardUpBoxBlock,
  StyledProductCardBottomBoxBlock,
  StyledProductCardImageBoxWrapper,
  StyledProductCardImage,
} from "@pages/ProductsPage/components/ProductCard/StyledProductCard";
import generateFileSrc from "@utils/generateFileSrc";
import imageOnErrorHandler from "@utils/handleImageError";

const ProductCard = ({
  id,
  image,
  name,
  prices,
  categoryName,
  manufacturerName,
  count,
  handleDeleteButton,
  dateOfChange = "25.04.2023",
}: ProductCardProps) => {
  return (
    <StyledProductCard>
      <StyledProductCardUpBoxBlock>
        <Grid2 container justifyContent="space-between" alignItems="center">
          <Grid2 container alignItems="center" spacing={"30px"} lg={5}>
            <Grid2>
              <StyledProductCardImageBoxWrapper>
                <StyledProductCardImage
                  src={image ? generateFileSrc(image) : errorImage}
                  alt={name}
                  onError={(event) => imageOnErrorHandler(event)}
                />
              </StyledProductCardImageBoxWrapper>
            </Grid2>

            <Grid2>
              <Typography variant="h5">{name}</Typography>
            </Grid2>
          </Grid2>

          <Grid2 lg={3}>
            <PriceBlock prices={prices} />
          </Grid2>

          <Grid2>
            <Stack direction="row" spacing={"15px"}>
              <RouterLink to={`/products/${String(id)}/edit`}>
                <IconButton
                  icon={<EditOutlinedIcon color="warning" />}
                  backgroundColor="rgba(251, 188, 4, 0.25)"
                />
              </RouterLink>

              <IconButton
                onClick={() => handleDeleteButton(id)}
                icon={<DeleteIcon htmlColor={theme.palette.error.light} />}
              />
            </Stack>
          </Grid2>
        </Grid2>
      </StyledProductCardUpBoxBlock>

      <StyledProductCardBottomBoxBlock>
        <Grid2 container alignItems="center" justifyContent="space-between">
          <Grid2 lg={3}>
            <Typography variant="body1">К: {categoryName}</Typography>
          </Grid2>

          <Grid2 lg={3}>
            <Typography variant="body1">П: {manufacturerName}</Typography>
          </Grid2>

          <Grid2 lg={3}>
            <Typography variant="body1">О: {count} кг</Typography>
          </Grid2>

          <Typography variant="body1">
            Дата изменения: {dateOfChange}
          </Typography>
        </Grid2>
      </StyledProductCardBottomBoxBlock>
    </StyledProductCard>
  );
};
export default ProductCard;
