export const EMAIL_REGEXP =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

export const EMAIL_PHONE_REGEXP =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})|((\+7|7|8)+([0-9]){10})$/iu;

export const CURRENCY_SELECT_OPTIONS = [
  {
    label: "RUB",
    value: "RUB",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "KZT",
    value: "KZT",
  },
];
