import { borderRadius } from "@constants/constants.style";
import { styled, Pagination, PaginationItem } from "@mui/material";

export const StyledPagination = styled(Pagination)`
  background: rgba(39, 39, 39, 0.05);
  max-width: 397px;
  border-radius: ${borderRadius};
  padding: 12px 0;
`;

export const StyledPaginationItem = styled(PaginationItem)`
  border-radius: 10px;
`;
