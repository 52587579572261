import NumberInput from "@components/form-components/NumberInput/NumberInput";
import { ReactHookFormInputProps } from "@components/form-components/ReactHookFormInput/ReactHookFormInputTypes";
import { StyledReactHookFormFileInput } from "@components/form-components/ReactHookFormInput/StyledReactHookFormInput";
import TextField from "@components/TextField/TextField";
import { FormControlLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const ReactHookFormInput = ({
  className,
  name,
  label,
  defaultValue,
  rules,
  isNumberInput,
  type = "text",
  inputProps,
  handleBlur,
  formControlLabelProps,
  ...restProps
}: ReactHookFormInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ ...rules }}
      render={({
        field: { onBlur, value, onChange, ref, ...restField },
        fieldState,
      }) => {
        if (type === "file") {
          return (
            <FormControlLabel
              disableTypography
              className={className}
              {...formControlLabelProps}
              control={
                <StyledReactHookFormFileInput
                  inputProps={{ ...inputProps }}
                  type={type}
                  helperText={
                    fieldState.error
                      ? fieldState.error.message || fieldState.error.type
                      : null
                  }
                  error={Boolean(fieldState.error)}
                  inputRef={ref}
                  value={value?.fileName}
                  onChange={(event) =>
                    onChange((event.target as HTMLInputElement).files)
                  }
                  onBlur={handleBlur ? () => handleBlur() : onBlur}
                  {...restField}
                  {...restProps}
                />
              }
              label={label}
            />
          );
        }
        if (isNumberInput) {
          return (
            <NumberInput
              className={className}
              type={type}
              inputProps={{ ...inputProps }}
              helperText={
                fieldState.error
                  ? fieldState.error.message || fieldState.error.type
                  : null
              }
              error={Boolean(fieldState.error)}
              value={value}
              onBlur={handleBlur ? () => handleBlur() : onBlur}
              onChange={onChange}
              inputRef={ref}
              {...restField}
              {...restProps}
            />
          );
        }
        return (
          <TextField
            className={className}
            type={type}
            value={value}
            inputProps={{ ...inputProps }}
            helperText={
              fieldState.error
                ? fieldState.error.message || fieldState.error.type
                : null
            }
            error={Boolean(fieldState.error)}
            onBlur={handleBlur ? () => handleBlur() : onBlur}
            onChange={onChange}
            inputRef={ref}
            {...restField}
            {...restProps}
          />
        );
      }}
    />
  );
};
export default ReactHookFormInput;
