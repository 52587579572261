import { RouterLinkProps } from "@components/RouterLink/RouterLinkTypes";
import { StyledRouterLink } from "@components/RouterLink/StyledRouterLink";
import theme from "@config/theme";

const RouterLink = ({
  children,
  underline,
  color = theme.palette.text.primary,
  ...rest
}: RouterLinkProps) => {
  return (
    <StyledRouterLink color={color} underline={underline} {...rest}>
      {children}
    </StyledRouterLink>
  );
};
export default RouterLink;
