import { useEffect, useState } from "react";

import CreateUpdateImageBlock from "@components/CreateUpdateImageBlock/CreateUpdateImageBlock";
import CreateUpdateSubtitle from "@components/CreateUpdateSubtitle/CreateUpdateSubtitle";
import MainContainer from "@components/MainContainer/MainContainer";
import StyledReactHookFormInput from "@components/StyledReactHookFormInput/StyledReactHookFormInput";
import StyledSubmitButton from "@components/StyledSubmitButton/StyledSubmitButton";
import Typography from "@components/Typography/Typography";
import { AcidFileServerModel } from "@customTypes/api/models/acid.model";
import { FileServerModel } from "@customTypes/api/models/file.model";
import { Box, Stack } from "@mui/material";
import { ManufacturerFormValue } from "@pages/ManufacturersPage/pages/CreateUpdateManufacturer/CreateUpdateManufacturerTypes";
import { useCreateFileMutation } from "@redux/services/file/fileApi";
import {
  useCreateManufacturerMutation,
  useLazyGetManufacturerByIdQuery,
  useUpdateManufacturerMutation,
} from "@redux/services/manufacturer/manufacturerApi";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const CreateUpdateManufacturer = () => {
  //  * Libraries

  const { manufacturerId } = useParams();

  const methods = useForm<ManufacturerFormValue>({
    defaultValues: {
      name: "",
      file: "",
    },
  });

  const { handleSubmit, reset } = methods;

  // * Local State
  const [manufacturerFile, setManufacturerFile] =
    useState<AcidFileServerModel | null>(null);

  // * Api services
  const [createManufacturer] = useCreateManufacturerMutation();

  const [createFile] = useCreateFileMutation();

  const [getManufacturerById, { data: fetchedManufacturerById }] =
    useLazyGetManufacturerByIdQuery();

  const [updateManufacturer] = useUpdateManufacturerMutation();

  // * Component's useEffect

  // Get manufacturer and reset form fields with fetchedManufacturer
  useEffect(() => {
    if (manufacturerId) {
      getManufacturerById(Number(manufacturerId));
    }

    if (!fetchedManufacturerById) return;

    setManufacturerFile(fetchedManufacturerById.file);

    reset({
      name: fetchedManufacturerById.name,
    });
  }, [fetchedManufacturerById, getManufacturerById, manufacturerId, reset]);

  // * Handlers
  const handleDeleteCategoryImage = () => {
    setManufacturerFile(null);
  };

  // Create Update Manufacturer
  const handleCreateUpdateManufacturer = async (
    formData: ManufacturerFormValue
  ) => {
    let manufacturerImage: FileServerModel | null = null;

    if (
      formData.file &&
      formData.file.length > 0 &&
      typeof formData.file !== "string"
    ) {
      manufacturerImage = await createFile({
        frontName: formData.file[0].name,
        file: formData.file[0],
      }).unwrap();
    }

    // Update Manufacturer
    if (fetchedManufacturerById && manufacturerId) {
      const updateManufacturerBody = {
        id: fetchedManufacturerById.id,
        name: formData.name,
        file: manufacturerImage || manufacturerFile,
        priority: fetchedManufacturerById.priority,
      };

      updateManufacturer(updateManufacturerBody);
      return;
    }

    // Create Manufacturer
    const createManufacturerBody = {
      name: formData.name,
      file: manufacturerImage ? manufacturerImage.name : null,
    };

    createManufacturer(createManufacturerBody);
    reset();
  };

  const handleSubmitForm = (data: ManufacturerFormValue) => {
    handleCreateUpdateManufacturer(data);
  };

  // * Layouts

  return (
    <MainContainer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box sx={{ marginTop: "90px" }}>
            <CreateUpdateSubtitle number="1" subtitle="Основная информация" />
            <Box sx={{ marginTop: "60px" }}>
              <Stack direction="column" spacing={"30px"}>
                <CreateUpdateImageBlock
                  name="file"
                  title="Логотип производителя:"
                  inputProps={{ multiple: false, accept: "image/*" }}
                  previewImages={manufacturerFile}
                  handleDeleteEditablePreviewImages={handleDeleteCategoryImage}
                />

                <Stack direction="column" spacing={"15px"}>
                  <Typography variant="body1" fontWeight="fontWeightBold">
                    Наименование производителя:
                  </Typography>

                  <StyledReactHookFormInput
                    name="name"
                    rules={{
                      required: "Поле обязательно для заполнения.",
                    }}
                  />
                </Stack>
              </Stack>
            </Box>
          </Box>
          <Box sx={{ marginTop: "60px", marginBottom: "120px" }}>
            <StyledSubmitButton />
          </Box>
        </form>
      </FormProvider>
    </MainContainer>
  );
};
export default CreateUpdateManufacturer;
