import { borderRadius } from "@constants/constants.style";
import { styled } from "@mui/material";

export const StyledLoginPageForm = styled("form")`
  margin: 0 auto;
  border: 1px solid rgba(39, 39, 39, 0.25);
  padding: 30px;
  max-width: 450px;
  border-radius: ${borderRadius};
`;
