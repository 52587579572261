import {
  CreateExtraActionBody,
  GetExtraActionsApiResponse,
} from "@customTypes/api/models/extraActions.model";
import { ExtraActionApiPaths } from "@redux/services/extraActions/extraActionApiPaths";
import generalApi from "@redux/services/initialApi";

export const extraActionApi = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    getExtraActions: builder.query<GetExtraActionsApiResponse, void>({
      query: () => ExtraActionApiPaths.ExtraActionsAll,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "ExtraActions" as const,
                id,
              })),
              { type: "ExtraActions", id: "LIST" },
            ]
          : [{ type: "ExtraActions", id: "LIST" }],
    }),

    createExtraAction: builder.mutation<void, CreateExtraActionBody>({
      query: ({ ...createExtraActionBody }) => ({
        url: ExtraActionApiPaths.ExtraActionsAdmin,
        method: "POST",
        body: createExtraActionBody,
      }),
      invalidatesTags: [{ type: "ExtraActions", id: "LIST" }],
    }),

    deleteExtraAction: builder.mutation<void, number>({
      query: (extraActionId) => ({
        url: ExtraActionApiPaths.ExtraActionsAdmin,
        method: "DELETE",
        params: { id: extraActionId },
      }),
      invalidatesTags: [{ type: "ExtraActions", id: "LIST" }],
    }),
  }),
});

export const {
  useGetExtraActionsQuery,
  useCreateExtraActionMutation,
  useDeleteExtraActionMutation,
} = extraActionApi;
