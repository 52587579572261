import { isFulfilled } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

export const rtkQuerySuccessLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isFulfilled()(action)) {
      toast.success(`Запрос прошел успешно!`, {
        position: "top-right",
      });
    }
    return next(action);
  };
