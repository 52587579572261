import { MainContainerProps } from "@components/MainContainer/MainContainerTypes";
import Container from "@mui/material/Container";

const MainContainer = ({
  className,
  children,
  maxWidth = "xl",
  ...restProps
}: MainContainerProps) => {
  return (
    <Container className={className} maxWidth={maxWidth} {...restProps}>
      {children}
    </Container>
  );
};
export default MainContainer;
