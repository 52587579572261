import CreateUpdateImageBlock from "@components/CreateUpdateImageBlock/CreateUpdateImageBlock";
import CreateUpdateSubtitle from "@components/CreateUpdateSubtitle/CreateUpdateSubtitle";
import SelectWithStyledOptions from "@components/SelectWithStyledOptions/SelectWithStyledOptions";
import StyledReactHookFormInput from "@components/StyledReactHookFormInput/StyledReactHookFormInput";
import Typography from "@components/Typography/Typography";
import { AcidFileServerModel } from "@customTypes/api/models/acid.model";
import { Box, Stack } from "@mui/material";
import {
  StyledCreateUpdateProductMainInfo,
  StyledTextArea,
} from "@pages/ProductsPage/pages/CreateUpdateProduct/components/CreateUpdateProductMainInfo/StyledCreateUpdateProductMainInfo";
import { useGetAcidTaresQuery } from "@redux/services/acidTare/acidTareApi";
import { useGetCategoriesTitlesQuery } from "@redux/services/category/categoryApi";
import { useGetManufacturersQuery } from "@redux/services/manufacturer/manufacturerApi";
import { useFormContext } from "react-hook-form";
import { slugify } from "transliteration";

type CreateUpdateProductMainInfoProps = {
  className?: string;
  acidFiles?: AcidFileServerModel[] | null;
  handleDeleteEditableAcidFile: (id: number) => void;
};

const CreateUpdateProductMainInfo = ({
  className,
  acidFiles,
  handleDeleteEditableAcidFile,
}: CreateUpdateProductMainInfoProps) => {
  const { getValues, setValue } = useFormContext();

  const { data: fetchedManufacturers } = useGetManufacturersQuery();

  const { data: fetchedCategoriesTitles } = useGetCategoriesTitlesQuery();

  const { data: fetchedTares } = useGetAcidTaresQuery();

  const handleNameFieldBlur = () => {
    const nameValue = getValues("name");
    if (nameValue) {
      setValue("frontName", slugify(nameValue));
    }
  };

  const categoriesSelectOptions = fetchedCategoriesTitles?.map((item) => {
    return { label: item.productCategories, value: String(item.id) };
  });

  const manufacturerSelectOptions = fetchedManufacturers?.map((item) => {
    return { label: item.name, value: String(item.id) };
  });

  const taresSelectOption = fetchedTares?.map((item) => {
    return { label: item.name, value: String(item.id) };
  });

  return (
    <StyledCreateUpdateProductMainInfo className={className}>
      <CreateUpdateSubtitle number="1" subtitle="Основная информация" />

      <Box sx={{ marginTop: "60px" }}>
        <Stack direction="column" spacing={"30px"}>
          <CreateUpdateImageBlock
            name="acidFiles"
            title="Изображение товара:"
            inputProps={{ multiple: true, accept: "image/*" }}
            previewImages={acidFiles}
            handleDeleteEditablePreviewImages={handleDeleteEditableAcidFile}
          />

          <Stack direction="column" spacing={"15px"}>
            <Typography variant="body1" fontWeight="fontWeightBold">
              Название товара:
            </Typography>

            <StyledReactHookFormInput
              name="name"
              handleBlur={handleNameFieldBlur}
              rules={{
                required: "Поле обязательно для заполнения.",
              }}
            />
          </Stack>

          <Stack direction="column" spacing={"15px"}>
            <Typography variant="body1" fontWeight="fontWeightBold">
              URL товара:
            </Typography>

            <StyledReactHookFormInput
              name="frontName"
              rules={{
                required: "Поле обязательно для заполнения.",
              }}
            />
          </Stack>

          <Stack direction="column" spacing={"15px"}>
            <Typography variant="body1" fontWeight="fontWeightBold">
              ГОСТ или ТУ:
            </Typography>

            <StyledReactHookFormInput name="gost" />
          </Stack>

          <Stack direction="column" spacing={"15px"}>
            <Typography variant="body1" fontWeight="fontWeightBold">
              Плотность
            </Typography>

            <StyledReactHookFormInput name="acidDensity" isNumberInput />
          </Stack>

          <Stack direction="column" spacing={"15px"}>
            <Typography variant="body1" fontWeight="fontWeightBold">
              Категория:
            </Typography>

            <SelectWithStyledOptions
              name="category"
              options={categoriesSelectOptions}
            />
          </Stack>

          <Stack direction="column" spacing={"15px"}>
            <Typography variant="body1" fontWeight="fontWeightBold">
              Производитель:
            </Typography>

            <SelectWithStyledOptions
              name="manufacturer"
              options={manufacturerSelectOptions}
            />
          </Stack>

          <Stack direction="column" spacing={"15px"}>
            <Typography variant="body1" fontWeight="fontWeightBold">
              Доступные тары:
            </Typography>

            <SelectWithStyledOptions
              name="availableTares"
              SelectProps={{ multiple: true }}
              options={taresSelectOption}
            />
          </Stack>

          <Stack direction="column" spacing={"15px"}>
            <Typography variant="body1" fontWeight="fontWeightBold">
              Объём:
            </Typography>

            <Stack direction="row" spacing={"15px"} alignItems="center">
              <StyledReactHookFormInput
                name="count"
                maxWidth="600px"
                fullWidth
                isNumberInput
              />

              <Typography variant="body1">килограммов</Typography>
            </Stack>
          </Stack>

          <Stack direction="column" spacing={"15px"}>
            <Typography variant="body1" fontWeight="fontWeightBold">
              Описание:
            </Typography>

            <div>
              <StyledTextArea name="description" />
            </div>
          </Stack>
        </Stack>
      </Box>
    </StyledCreateUpdateProductMainInfo>
  );
};
export default CreateUpdateProductMainInfo;
