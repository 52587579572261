import PrimaryLogo from "@assets/icons/PrimaryLogo/PrimaryLogo";
import { LogoProps } from "@components/Logo/LogoTypes";
import { StyledLogoStackContainer } from "@components/Logo/StyledLogo";
import RoutePaths from "@routes/routePaths";
import { useNavigate } from "react-router-dom";

const Logo = ({ logoContainerProps }: LogoProps) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(RoutePaths.MainPage);
  };

  return (
    <StyledLogoStackContainer
      direction="row"
      onClick={handleRedirect}
      {...logoContainerProps}
    >
      <PrimaryLogo />
    </StyledLogoStackContainer>
  );
};
export default Logo;
