import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1A73E8",
    },
    secondary: {
      main: "#272727",
    },
    error: {
      main: "#d32f2f",
      light: "#F06054",
    },
    warning: {
      main: "#FBBC04",
    },
    success: {
      main: "#50D890",
    },
    background: {
      default: "#F9F9F9",
    },

    text: {
      primary: "#272727",
      secondary: "#27272780",
    },
  },

  typography: {
    h1: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "64px",
      lineHeight: "75px",
    },
    h2: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "48px",
      lineHeight: "56px",
    },
    h3: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "36px",
      lineHeight: "42px",
    },
    h4: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "36px",
      lineHeight: "42px",
    },
    h5: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "28px",
    },
    body1: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "23px",
    },
    body2: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "19px",
    },
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          textTransform: "none",
          boxShadow: "none",
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              "&:hover": {
                boxShadow: "none",
                backgroundColor: theme.palette.primary.main,
              },
            }),
        }),
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
});

export default theme;
