import CreateUpdateSubtitle from "@components/CreateUpdateSubtitle/CreateUpdateSubtitle";
import MainContainer from "@components/MainContainer/MainContainer";
import StyledReactHookFormInput from "@components/StyledReactHookFormInput/StyledReactHookFormInput";
import StyledSubmitButton from "@components/StyledSubmitButton/StyledSubmitButton";
import Typography from "@components/Typography/Typography";
import { EMAIL_PHONE_REGEXP } from "@constants/constants";
import { Box, Stack } from "@mui/material";
import { useCreateContactMutation } from "@redux/services/contact/contactApi";
import { useForm, FormProvider } from "react-hook-form";

type ContactFormValues = {
  name: string;
  description: string;
  number: string;
};

const CreateUpdateContact = () => {
  //  * Libraries
  const methods = useForm<ContactFormValues>({
    defaultValues: {
      name: "",
      description: "",
      number: "",
    },
  });

  const { handleSubmit, reset } = methods;

  // * Api services
  const [createContact] = useCreateContactMutation();

  // * Handlers

  const handleCreateContact = async (formData: ContactFormValues) => {
    createContact(formData);

    reset();
  };

  const handleSubmitForm = (data: ContactFormValues) => {
    handleCreateContact(data);
  };

  // * Layouts
  return (
    <MainContainer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box sx={{ marginTop: "90px" }}>
            <CreateUpdateSubtitle number="1" subtitle="Основная информация" />
            <Box sx={{ marginTop: "60px" }}>
              <Stack direction="column" spacing={"30px"}>
                <Stack direction="column" spacing={"15px"}>
                  <Typography variant="body1" fontWeight="fontWeightBold">
                    Номер телефона или e-mail:
                  </Typography>

                  <StyledReactHookFormInput
                    name="number"
                    rules={{
                      required: "Поле обязательно для заполнения.",
                      pattern: {
                        value: EMAIL_PHONE_REGEXP,
                        message: "Некорректный ввод.",
                      },
                    }}
                  />
                </Stack>

                <Stack direction="column" spacing={"15px"}>
                  <Typography variant="body1" fontWeight="fontWeightBold">
                    Название:
                  </Typography>

                  <StyledReactHookFormInput
                    name="name"
                    rules={{
                      required: "Поле обязательно для заполнения.",
                    }}
                  />
                </Stack>

                <Stack direction="column" spacing={"15px"}>
                  <Typography variant="body1" fontWeight="fontWeightBold">
                    Описание:
                  </Typography>

                  <StyledReactHookFormInput name="description" />
                </Stack>
              </Stack>
            </Box>
          </Box>
          <Box sx={{ marginTop: "60px", marginBottom: "120px" }}>
            <StyledSubmitButton />
          </Box>
        </form>
      </FormProvider>
    </MainContainer>
  );
};
export default CreateUpdateContact;
