import {
  GetDataWithPagination,
  PaginationParams,
} from "@customTypes/api/general";
import {
  CreateCategoryBody,
  GetCategoriesAllOnPageApiResponse,
  GetCategoriesTitlesApiResponse,
  GetCategoryApiResponse,
  UpdateCategoryBody,
} from "@customTypes/api/models/category.model";
import { CategoryApiPaths } from "@redux/services/category/categoryApiPaths";
import generalApi from "@redux/services/initialApi";

export const categoryApi = generalApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getCategoriesTitles: builder.query<GetCategoriesTitlesApiResponse, void>({
        query: () => {
          return {
            url: CategoryApiPaths.CategoriesTitles,
          };
        },

        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Categories" as const,
                  id,
                })),
                { type: "Categories", id: "LIST" },
              ]
            : [{ type: "Categories", id: "LIST" }],
      }),

      getCategoryById: builder.query<GetCategoryApiResponse, number>({
        query: (id) => CategoryApiPaths.Categories + id,
      }),

      getCategoriesAllOnPage: builder.query<
        GetDataWithPagination<GetCategoriesAllOnPageApiResponse>,
        PaginationParams
      >({
        query: (params) => ({
          url: CategoryApiPaths.CategoriesAllOnPage,
          params: { ...params },
        }),

        providesTags: (result) =>
          result
            ? [
                ...result.content.map(({ id }) => ({
                  type: "Categories" as const,
                  id,
                })),
                { type: "Categories", id: "LIST" },
              ]
            : [{ type: "Categories", id: "LIST" }],
      }),

      createCategory: builder.mutation<void, CreateCategoryBody>({
        query: ({ ...createCategoryBody }) => ({
          url: CategoryApiPaths.CategoriesAdmin,
          method: "POST",
          body: createCategoryBody,
        }),
        invalidatesTags: [{ type: "Categories", id: "LIST" }],
      }),

      deleteCategory: builder.mutation<boolean, number>({
        query: (categoryId) => ({
          url: CategoryApiPaths.Categories,
          method: "DELETE",
          params: { id: categoryId },
        }),
        invalidatesTags: [{ type: "Categories", id: "LIST" }],
      }),

      updateCategory: builder.mutation<void, UpdateCategoryBody>({
        query: ({ ...updateCategoryBody }) => ({
          url: CategoryApiPaths.CategoriesAdmin,
          method: "PUT",
          body: updateCategoryBody,
        }),
        invalidatesTags: [{ type: "Categories", id: "LIST" }],
      }),
    };
  },
});

export const {
  useGetCategoriesTitlesQuery,
  useLazyGetCategoryByIdQuery,
  useGetCategoriesAllOnPageQuery,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
} = categoryApi;
