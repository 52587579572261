import { borderRadius } from "@constants/constants.style";
import { Box, styled } from "@mui/material";

export const StyledEntityNavigationBlock = styled(Box)`
  padding: 20px;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: ${borderRadius};
  transition: all 0.5s ease-out;
  :hover {
    background: ${({ theme }) => theme.palette.primary.main};
    p {
      color: ${({ theme }) => theme.palette.common.white};
      transition: all 0.52s ease-out;
    }
    cursor: pointer;
  }
`;
