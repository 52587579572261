import {
  CreateAcidTareBody,
  GetAcidTaresApiResponse,
  UpdateAcidTareBody,
} from "@customTypes/api/models/acidTare.model";
import { AcidTareApiPaths } from "@redux/services/acidTare/acidTareApiPaths";
import generalApi from "@redux/services/initialApi";

export const acidTareApi = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    getAcidTares: builder.query<GetAcidTaresApiResponse, void>({
      query: () => AcidTareApiPaths.TareAll,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Tares" as const,
                id,
              })),
              { type: "Tares", id: "LIST" },
            ]
          : [{ type: "Tares", id: "LIST" }],
    }),

    createAcidTare: builder.mutation<void, CreateAcidTareBody>({
      query: ({ ...creatingTareBody }) => ({
        url: AcidTareApiPaths.TareAdmin,
        method: "POST",
        body: creatingTareBody,
      }),
      invalidatesTags: [{ type: "Tares", id: "LIST" }],
    }),

    deleteAcidTare: builder.mutation<void, number>({
      query: (tareId) => ({
        url: AcidTareApiPaths.TareAdmin,
        method: "DELETE",
        params: { id: tareId },
      }),
      invalidatesTags: [{ type: "Tares", id: "LIST" }],
    }),

    updateAcidTare: builder.mutation<void, UpdateAcidTareBody>({
      query: ({ ...updateAcidTareBody }) => ({
        url: AcidTareApiPaths.Tare,
        method: "PUT",
        body: updateAcidTareBody,
      }),
      invalidatesTags: [{ type: "Tares", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAcidTaresQuery,
  useCreateAcidTareMutation,
  useDeleteAcidTareMutation,
  useUpdateAcidTareMutation,
} = acidTareApi;
