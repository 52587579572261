import Button from "@components/Button/Button";
import { borderRadius } from "@constants/constants.style";
import { styled } from "@mui/material";

export const StyledCreateUpdateProductAddButton = styled(Button)`
  border-radius: ${borderRadius};
  background: rgba(26, 115, 232, 0.15);
  padding: 16px 0;
  font-size: 20px;
  line-height: 23px;
  color: ${({ theme }) => theme.palette.primary.main};
`;
