import { RouterLinkProps } from "@components/RouterLink/RouterLinkTypes";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export const StyledRouterLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "underline" && prop !== "color",
})<RouterLinkProps>`
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  color: ${({ color, theme }) => (color ? color : theme.palette.text.primary)};
  :visited {
    color: ${({ color, theme }) =>
      color ? color : theme.palette.text.primary};
  }
`;
