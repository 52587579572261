import CreateUpdateSubtitle from "@components/CreateUpdateSubtitle/CreateUpdateSubtitle";
import MainContainer from "@components/MainContainer/MainContainer";
import StyledReactHookFormInput from "@components/StyledReactHookFormInput/StyledReactHookFormInput";
import StyledSubmitButton from "@components/StyledSubmitButton/StyledSubmitButton";
import Typography from "@components/Typography/Typography";
import { Box, Stack } from "@mui/material";
import { useCreateInfoMessageMutation } from "@redux/services/infoMessage/infoMessageApi";
import { FormProvider, useForm } from "react-hook-form";

type InfoMessageFormValues = {
  message: string;
};
const CreateInfoMessage = () => {
  //  * Libraries
  const methods = useForm<InfoMessageFormValues>({
    defaultValues: {
      message: "",
    },
  });

  const { handleSubmit, reset } = methods;

  // * Api services
  const [createInfoMessage] = useCreateInfoMessageMutation();

  // * Handlers
  const handleSubmitForm = (formData: InfoMessageFormValues) => {
    createInfoMessage(formData);

    reset();
  };

  // * Layouts
  return (
    <MainContainer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box sx={{ marginTop: "90px" }}>
            <Stack direction="column" spacing={"60px"}>
              <div>
                <CreateUpdateSubtitle
                  number="1"
                  subtitle="Основная информация"
                />
                <Box sx={{ marginTop: "60px" }}>
                  <Stack direction="column" spacing={"15px"}>
                    <Typography variant="body1" fontWeight="fontWeightBold">
                      Текст сообщения:
                    </Typography>

                    <StyledReactHookFormInput name="message" />
                  </Stack>
                </Box>
              </div>
            </Stack>
          </Box>
          <Box sx={{ marginTop: "60px", marginBottom: "120px" }}>
            <StyledSubmitButton />
          </Box>
        </form>
      </FormProvider>
    </MainContainer>
  );
};
export default CreateInfoMessage;
