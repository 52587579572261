import {
  StyledHeader,
  StyledHeaderButton,
} from "@components/Header/StyledHeader";
import Logo from "@components/Logo/Logo";
import MainContainer from "@components/MainContainer/MainContainer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Stack } from "@mui/material";
import { logout } from "@redux/features/userSlice/userSlice";
import RoutePaths from "@routes/routePaths";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const isShowLogOutButton = location.pathname !== RoutePaths.LoginPage;

  const logOutHandler = () => {
    dispatch(logout());
    navigate(RoutePaths.LoginPage, {
      state: { from: { pathname: location.pathname } },
    });
  };

  return (
    <MainContainer>
      <StyledHeader>
        <Stack
          direction="row"
          justifyContent={isShowLogOutButton ? "space-between" : "center"}
          alignItems="center"
        >
          <Logo logoContainerProps={{ spacing: "15px" }} />

          {isShowLogOutButton && (
            <StyledHeaderButton
              variant="contained"
              endIcon={
                <ArrowForwardIcon
                  sx={{ color: "rgba(255, 255, 255, 0.25);" }}
                />
              }
              onClick={logOutHandler}
            >
              Лог аут
            </StyledHeaderButton>
          )}
        </Stack>
      </StyledHeader>
    </MainContainer>
  );
};
export default Header;
