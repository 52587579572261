import MainContainer from "@components/MainContainer/MainContainer";
import StyledSubmitButton from "@components/StyledSubmitButton/StyledSubmitButton";
import { Box, Stack } from "@mui/material";
import ContactEmployeeData from "@pages/EmployeesPage/components/ContactEmployeeData/ContactEmployeeData";
import MainEmployeeInfo from "@pages/EmployeesPage/components/MainEmployeeInfo/MainEmployeeInfo";
import { useCreateEmployeeMutation } from "@redux/services/employee/employeeApi";
import { FormProvider, useForm } from "react-hook-form";

type EmployeeFormValues = {
  name: string;
  description: string;
  number: string;
  employerName: string;
  email: string;
};

const CreateUpdateEmployee = () => {
  //  * Libraries
  const methods = useForm<EmployeeFormValues>({
    defaultValues: {
      name: "",
      description: "",
      number: "",
      employerName: "",
      email: "",
    },
  });

  const { handleSubmit, reset } = methods;

  // * Api services
  const [createEmployee] = useCreateEmployeeMutation();

  /**
   *  * Handlers
   */
  // Create Update Employee
  const handleCreateUpdateEmployee = async (formData: EmployeeFormValues) => {
    createEmployee(formData);

    reset();
  };

  const handleSubmitForm = (data: EmployeeFormValues) => {
    handleCreateUpdateEmployee(data);
  };

  // * Layouts

  return (
    <MainContainer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box sx={{ marginTop: "90px" }}>
            <Stack direction="column" spacing={"60px"}>
              <MainEmployeeInfo />

              <ContactEmployeeData />
            </Stack>
          </Box>

          <Box sx={{ marginTop: "60px", marginBottom: "120px" }}>
            <StyledSubmitButton />
          </Box>
        </form>
      </FormProvider>
    </MainContainer>
  );
};
export default CreateUpdateEmployee;
