import Typography from "@components/Typography/Typography";
import { StyledWithErrorMessageBlock } from "@components/WithErrorMessage/StyledWithErrorMessage";
import { WithErrorMessageProps } from "@components/WithErrorMessage/WithErrorMessageTypes";
import { useTheme, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const WithErrorMessage = ({
  className,
  children,
  isError,
  stackProps,
  typographyProps,
}: WithErrorMessageProps) => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const errorMessage =
    "Что-то пошло не так :( \n Попробуйте перезагрузить страницу";

  if (isError) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        {...stackProps}
      >
        <StyledWithErrorMessageBlock
          className={className}
          isMobileScreen={isMobileScreen}
        >
          <Typography
            textAlign="center"
            variant={isMobileScreen ? "body2" : "body1"}
            color="common.white"
            sx={{ whiteSpace: "pre-line" }}
            {...typographyProps}
          >
            {errorMessage}
          </Typography>
        </StyledWithErrorMessageBlock>
      </Stack>
    );
  }

  return <>{children}</>;
};
export default WithErrorMessage;
