import Button from "@components/Button/Button";
import { styled } from "@mui/material";

export const StyledCreateUpdateSubtitleButton = styled(Button)`
  width: 38px;
  height: 38px;
  min-width: 38px;
  padding: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  cursor: auto;
`;
