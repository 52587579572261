import { useCallback } from "react";

import AddButtonLink from "@components/AddButtonLink/AddButtonLink";
import CommonCardEntity from "@components/CommonCardEntity/CommonCardEntity";
import MainContainer from "@components/MainContainer/MainContainer";
import Typography from "@components/Typography/Typography";
import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import { Stack } from "@mui/material";
import {
  useGetAcidTaresQuery,
  useDeleteAcidTareMutation,
} from "@redux/services/acidTare/acidTareApi";
import RoutePaths from "@routes/routePaths";

const TaresPage = () => {
  // * Api services
  const {
    data: fetchedTares,
    isLoading: isFetchedTaresLoading,
    isError: isFetchedTaresError,
  } = useGetAcidTaresQuery();

  const [deleteTare] = useDeleteAcidTareMutation();

  // * Handlers
  const handleDeleteTare = useCallback(
    (tareId: number) => {
      deleteTare(tareId);
    },
    [deleteTare]
  );

  // * Layouts
  const taresCards = fetchedTares?.map((elem) => {
    const tareFileName = !elem.file ? null : elem.file.name;

    return (
      <CommonCardEntity
        key={elem.id}
        id={elem.id}
        title={elem.name}
        image={tareFileName}
        linkToEditRoute={`/tares/${String(elem.id)}/edit`}
        handleDeleteButton={handleDeleteTare}
      />
    );
  });

  return (
    <MainContainer sx={{ marginBottom: "100px" }}>
      <Stack
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography variant="h3">Тара (заявка)</Typography>

        <AddButtonLink title="Добавить тару" to={RoutePaths.CreateTare} />
      </Stack>

      <WithLoader isLoading={isFetchedTaresLoading}>
        <WithErrorMessage
          isError={isFetchedTaresError}
          stackProps={{
            style: { margin: "200px 0" },
          }}
        >
          <Stack direction="column" sx={{ marginTop: "30px" }} spacing={"60px"}>
            {taresCards}
          </Stack>
        </WithErrorMessage>
      </WithLoader>
    </MainContainer>
  );
};
export default TaresPage;
