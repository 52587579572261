import { useCallback, useEffect, useState } from "react";

import CreateUpdateImageBlock from "@components/CreateUpdateImageBlock/CreateUpdateImageBlock";
import CreateUpdateSubtitle from "@components/CreateUpdateSubtitle/CreateUpdateSubtitle";
import MainContainer from "@components/MainContainer/MainContainer";
import SelectWithStyledOptions from "@components/SelectWithStyledOptions/SelectWithStyledOptions";
import StyledReactHookFormInput from "@components/StyledReactHookFormInput/StyledReactHookFormInput";
import StyledSubmitButton from "@components/StyledSubmitButton/StyledSubmitButton";
import Typography from "@components/Typography/Typography";
import { CURRENCY_SELECT_OPTIONS } from "@constants/constants";
import { AcidFileServerModel } from "@customTypes/api/models/acid.model";
import { AcidTareServerModel } from "@customTypes/api/models/acidTare.model";
import { Box, Stack } from "@mui/material";
import { TaresFormValues } from "@pages/TaresPage/pages/CreateUpdateTare/CreateUpdateTareTypes";
import {
  useCreateAcidTareMutation,
  useGetAcidTaresQuery,
  useUpdateAcidTareMutation,
} from "@redux/services/acidTare/acidTareApi";
import { useCreateFileMutation } from "@redux/services/file/fileApi";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";

const CreateUpdateTare = () => {
  //  * Libraries

  // Get URL Params
  const { tareId } = useParams();

  const methods = useForm<TaresFormValues>({
    defaultValues: {
      name: "",
      volume: "",
      tarePrice: { price: "", currency: "RUB", count: 0 },
      file: "",
    },
  });

  const { handleSubmit, reset } = methods;

  // * Local State
  const [tareFile, setTareFile] = useState<AcidFileServerModel | null>(null);
  const [currentTare, setCurrentTare] = useState<AcidTareServerModel | null>(
    null
  );

  // * Api services
  const [createFile] = useCreateFileMutation();

  const [createTare] = useCreateAcidTareMutation();

  const { data: fetchedTares } = useGetAcidTaresQuery();

  const [updateTare] = useUpdateAcidTareMutation();

  // * Component's useEffect

  // Get tares and reset form fields with fetchedTares
  useEffect(() => {
    if (!tareId || !fetchedTares) return;

    // Filtering fetchedTares to find current tare, because we don`t have get tare be id method
    const filteredFetchedTares = fetchedTares.filter(
      (elem) => elem.id === Number(tareId)
    );

    const currentTare = filteredFetchedTares[0];

    setCurrentTare(currentTare);

    setTareFile(currentTare.file);

    reset({
      name: currentTare.name,
      volume: currentTare.volume,
      tarePrice: currentTare.tarePrice,
    });
  }, [fetchedTares, reset, tareId]);

  // * Handlers
  const handleDeleteEditableTareFile = useCallback(() => {
    if (!tareFile) return;

    setTareFile(null);
  }, [tareFile]);

  const handleCreateUpdateTare = async (formData: TaresFormValues) => {
    let tareImage: AcidFileServerModel | null = null;

    if (
      formData.file &&
      formData.file.length > 0 &&
      typeof formData.file !== "string"
    ) {
      tareImage = await createFile({
        file: formData.file[0],
        frontName: formData.file[0].name,
      }).unwrap();
    }

    const updateTarePrice = {
      ...formData.tarePrice,
      price: formData.tarePrice.price ? Number(formData.tarePrice.price) : null,
      id: formData.tarePrice.id ? Number(formData.tarePrice.id) : null,
    };

    if (currentTare && tareId) {
      const updateTareBody = {
        id: currentTare.id,
        name: formData.name,
        file: tareImage || tareFile,
        volume: formData.volume ? Number(formData.volume) : null,
        tarePrice: updateTarePrice,
      };

      updateTare(updateTareBody);
      return;
    }

    const createTarePrice = {
      ...formData.tarePrice,
      price: formData.tarePrice.price ? Number(formData.tarePrice.price) : null,
    };

    const creatingTareBody = {
      ...formData,
      tarePrice: createTarePrice,
      volume: formData.volume ? Number(formData.volume) : null,
      file: tareImage ? tareImage.name : tareImage,
    };

    createTare(creatingTareBody);

    reset();
  };

  const handleSubmitForm = (data: TaresFormValues) => {
    handleCreateUpdateTare(data);
  };

  // * Layouts
  return (
    <MainContainer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box sx={{ marginTop: "90px" }}>
            <Stack direction="column" spacing={"60px"}>
              <div>
                <CreateUpdateSubtitle
                  number="1"
                  subtitle="Основная информация"
                />
                <Box sx={{ marginTop: "60px" }}>
                  <Stack direction="column" spacing={"30px"}>
                    <CreateUpdateImageBlock
                      name="file"
                      title="Изображение тары:"
                      inputProps={{ multiple: false, accept: "image/*" }}
                      previewImages={tareFile}
                      handleDeleteEditablePreviewImages={
                        handleDeleteEditableTareFile
                      }
                    />

                    <Stack direction="column" spacing={"15px"}>
                      <Typography variant="body1" fontWeight="fontWeightBold">
                        Название тары:
                      </Typography>

                      <StyledReactHookFormInput
                        name="name"
                        rules={{
                          required: "Поле обязательно для заполнения.",
                        }}
                      />
                    </Stack>

                    <Stack direction="column" spacing={"15px"}>
                      <Typography variant="body1" fontWeight="fontWeightBold">
                        Объем тары:
                      </Typography>

                      <StyledReactHookFormInput
                        name="volume"
                        isNumberInput
                        rules={{
                          required: "Поле обязательно для заполнения.",
                        }}
                      />
                    </Stack>
                  </Stack>
                </Box>
              </div>

              <div>
                <CreateUpdateSubtitle number="2" subtitle="Цена тары" />
                <Box sx={{ marginTop: "60px" }}>
                  <Stack direction="column" spacing={"30px"}>
                    <Stack direction="column" spacing={"15px"}>
                      <Typography variant="body1" fontWeight="fontWeightBold">
                        Цена за одну штуку:
                      </Typography>

                      <Stack direction="row" spacing={"15px"}>
                        <StyledReactHookFormInput
                          name="tarePrice.price"
                          isNumberInput
                        />

                        <SelectWithStyledOptions
                          name="tarePrice.currency"
                          options={CURRENCY_SELECT_OPTIONS}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              </div>
            </Stack>
          </Box>
          <Box sx={{ marginTop: "60px", marginBottom: "120px" }}>
            <StyledSubmitButton />
          </Box>
        </form>
      </FormProvider>
    </MainContainer>
  );
};
export default CreateUpdateTare;
