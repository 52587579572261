import { useEffect, useState } from "react";

import CreateUpdateImageBlock from "@components/CreateUpdateImageBlock/CreateUpdateImageBlock";
import CreateUpdateSubtitle from "@components/CreateUpdateSubtitle/CreateUpdateSubtitle";
import MainContainer from "@components/MainContainer/MainContainer";
import StyledReactHookFormInput from "@components/StyledReactHookFormInput/StyledReactHookFormInput";
import StyledSubmitButton from "@components/StyledSubmitButton/StyledSubmitButton";
import Typography from "@components/Typography/Typography";
import { AcidFileServerModel } from "@customTypes/api/models/acid.model";
import { FileServerModel } from "@customTypes/api/models/file.model";
import { Box, Stack } from "@mui/material";
import { CategoryFormValue } from "@pages/CategoriesPage/pages/CreateUpdateCategory/CreateUpdateCategoryTypes";
import {
  useCreateCategoryMutation,
  useLazyGetCategoryByIdQuery,
  useUpdateCategoryMutation,
} from "@redux/services/category/categoryApi";
import { useCreateFileMutation } from "@redux/services/file/fileApi";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { slugify } from "transliteration";

const CreateUpdateCategory = () => {
  //  * Libraries
  const methods = useForm<CategoryFormValue>({
    defaultValues: {
      name: "",
      file: "",
      frontName: "",
    },
  });

  const { categoryId } = useParams();

  const { handleSubmit, reset, getValues, setValue } = methods;

  // * Local State
  const [categoryFile, setCategoryFile] = useState<AcidFileServerModel | null>(
    null
  );

  // * Api services
  const [createFile] = useCreateFileMutation();

  const [createCategory] = useCreateCategoryMutation();

  const [updateCategory] = useUpdateCategoryMutation();

  const [getCategoryById, { data: fetchedCategoryById }] =
    useLazyGetCategoryByIdQuery();

  // * useEffects
  useEffect(() => {
    if (categoryId) {
      getCategoryById(Number(categoryId));
    }

    if (!fetchedCategoryById) return;

    setCategoryFile(fetchedCategoryById.file);

    reset({
      name: fetchedCategoryById.productCategories,
      frontName:
        fetchedCategoryById.frontName ||
        slugify(fetchedCategoryById.productCategories),
    });
  }, [categoryId, fetchedCategoryById, getCategoryById, reset]);

  // * Handlers
  const handleNameFieldBlur = () => {
    const nameValue = getValues("name");
    if (nameValue) {
      setValue("frontName", slugify(nameValue));
    }
  };

  const handleDeleteCategoryImage = () => {
    setCategoryFile(null);
  };

  // Create Update Category
  const handleCreateUpdateCategory = async (formData: CategoryFormValue) => {
    let categoryImage: FileServerModel | null = null;

    if (
      formData.file &&
      formData.file.length > 0 &&
      typeof formData.file !== "string"
    ) {
      categoryImage = await createFile({
        frontName: formData.file[0].name,
        file: formData.file[0],
      }).unwrap();
    }

    // Update Category
    if (fetchedCategoryById && categoryId) {
      const updateCategoryBody = {
        id: fetchedCategoryById.id,
        productCategories: formData.name,
        frontName: formData.frontName,
        file: categoryImage || categoryFile,
        priority: fetchedCategoryById.priority,
      };

      updateCategory(updateCategoryBody);
      return;
    }

    // Create Category
    const createCategoryBody = {
      productCategories: formData.name,
      frontName: formData.frontName,
      file: categoryImage ? categoryImage.name : null,
    };

    createCategory(createCategoryBody);
    reset();
  };

  const handleSubmitForm = (data: CategoryFormValue) => {
    handleCreateUpdateCategory(data);
  };

  // * Layouts
  return (
    <MainContainer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box sx={{ marginTop: "90px" }}>
            <CreateUpdateSubtitle number="1" subtitle="Основная информация" />
            <Box sx={{ marginTop: "60px" }}>
              <Stack direction="column" spacing={"30px"}>
                <CreateUpdateImageBlock
                  name="file"
                  title="Изображение категории:"
                  inputProps={{ multiple: false, accept: "image/*" }}
                  previewImages={categoryFile}
                  handleDeleteEditablePreviewImages={handleDeleteCategoryImage}
                />

                <Stack direction="column" spacing={"15px"}>
                  <Typography variant="body1" fontWeight="fontWeightBold">
                    Название категории:
                  </Typography>

                  <StyledReactHookFormInput
                    name="name"
                    handleBlur={handleNameFieldBlur}
                    rules={{
                      required: "Поле обязательно для заполнения.",
                    }}
                  />
                </Stack>

                <Stack direction="column" spacing={"15px"}>
                  <Typography variant="body1" fontWeight="fontWeightBold">
                    URL категории:
                  </Typography>

                  <StyledReactHookFormInput
                    name="frontName"
                    rules={{
                      required: "Поле обязательно для заполнения.",
                    }}
                  />
                </Stack>
              </Stack>
            </Box>
          </Box>
          <Box sx={{ marginTop: "60px", marginBottom: "120px" }}>
            <StyledSubmitButton />
          </Box>
        </form>
      </FormProvider>
    </MainContainer>
  );
};
export default CreateUpdateCategory;
