import CreateUpdateSubtitle from "@components/CreateUpdateSubtitle/CreateUpdateSubtitle";
import StyledReactHookFormInput from "@components/StyledReactHookFormInput/StyledReactHookFormInput";
import Typography from "@components/Typography/Typography";
import { EMAIL_REGEXP } from "@constants/constants";
import { Box, Stack } from "@mui/material";

const ContactEmployeeData = () => {
  return (
    <div>
      <CreateUpdateSubtitle number="2" subtitle="Контактные данные" />
      <Box sx={{ marginTop: "60px" }}>
        <Stack direction="column" spacing={"30px"}>
          <Stack direction="column" spacing={"15px"}>
            <Typography variant="body1" fontWeight="fontWeightBold">
              Номер телефона:
            </Typography>

            <StyledReactHookFormInput
              name="number"
              inputProps={{
                maxLength: 11,
              }}
              isNumberInput
              rules={{
                required: "Поле обязательно для заполнения",
                minLength: {
                  value: 10,
                  message: "Введите правильный номер",
                },
              }}
            />
          </Stack>

          <Stack direction="column" spacing={"15px"}>
            <Typography variant="body1" fontWeight="fontWeightBold">
              Электронная почта:
            </Typography>

            <StyledReactHookFormInput
              name="email"
              type="email"
              rules={{
                required: "Поле обязательно для заполнения.",
                pattern: {
                  value: EMAIL_REGEXP,
                  message: "Некорректный email.",
                },
              }}
            />
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};
export default ContactEmployeeData;
