import { useCallback } from "react";

import AddButtonLink from "@components/AddButtonLink/AddButtonLink";
import CommonCardEntity from "@components/CommonCardEntity/CommonCardEntity";
import MainContainer from "@components/MainContainer/MainContainer";
import Typography from "@components/Typography/Typography";
import WithErrorMessage from "@components/WithErrorMessage/WithErrorMessage";
import WithLoader from "@components/WithLoader/WithLoader";
import { Stack } from "@mui/material";
import {
  useDeleteManufacturerMutation,
  useGetManufacturersQuery,
} from "@redux/services/manufacturer/manufacturerApi";
import RoutePaths from "@routes/routePaths";

const ManufacturersPage = () => {
  // * Api services
  const {
    data: fetchedManufacturers,
    isLoading: isManufacturersLoading,
    isError: isManufacturersError,
  } = useGetManufacturersQuery();

  const [deleteManufacturer] = useDeleteManufacturerMutation();

  // * Handlers
  const handleDeleteManufacturer = useCallback(
    (manufacturerId: number) => {
      deleteManufacturer(manufacturerId);
    },
    [deleteManufacturer]
  );

  // * Layouts
  const manufacturersCards = fetchedManufacturers?.map((elem) => {
    const manufacturerFileName = !elem.file ? null : elem.file.name;

    return (
      <CommonCardEntity
        key={elem.id}
        id={elem.id}
        title={elem.name}
        image={manufacturerFileName}
        handleDeleteButton={handleDeleteManufacturer}
        linkToEditRoute={`/manufacturers/${String(elem.id)}/edit`}
      />
    );
  });

  return (
    <MainContainer sx={{ marginBottom: "100px" }}>
      <Stack
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography variant="h3">Производители</Typography>

        <AddButtonLink
          title="Добавить производителя"
          to={RoutePaths.CreateManufacturer}
        />
      </Stack>

      <WithLoader isLoading={isManufacturersLoading}>
        <WithErrorMessage
          isError={isManufacturersError}
          stackProps={{
            style: { margin: "200px 0" },
          }}
        >
          <Stack direction="column" sx={{ marginTop: "30px" }} spacing={"60px"}>
            {manufacturersCards}
          </Stack>
        </WithErrorMessage>
      </WithLoader>
    </MainContainer>
  );
};
export default ManufacturersPage;
