import RouterLink from "@components/RouterLink/RouterLink";
import Typography from "@components/Typography/Typography";
import { EntityNavigationBlockProps } from "@pages/MainPage/components/EntityNavigationBlock/EntityNavigationBlockTypes";
import { StyledEntityNavigationBlock } from "@pages/MainPage/components/EntityNavigationBlock/StyledEntityNavigationBlock";

const EntityNavigationBlock = ({ title, to }: EntityNavigationBlockProps) => {
  return (
    <RouterLink to={to}>
      <StyledEntityNavigationBlock>
        <Typography variant="body1">{title}</Typography>
      </StyledEntityNavigationBlock>
    </RouterLink>
  );
};
export default EntityNavigationBlock;
