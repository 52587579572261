import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";

export const setTokenInCookie = (accessToken: string, refreshToken: string) => {
  const cookies = new Cookies();
  cookies.set("token", accessToken, { path: "/" });
  cookies.set("refreshToken", refreshToken, { path: "/" });
};

export const getTokenFromCookie = () => {
  const cookies = new Cookies();
  return cookies.get("token");
};

export const getRefreshTokenFromCookie = () => {
  const cookies = new Cookies();
  return cookies.get("refreshToken");
};

export const tokenValid = (token: string) => {
  try {
    const decodedToken: any = jwt_decode(token);
    if (Date.now() >= decodedToken.exp * 1000) {
      return false;
    }
  } catch (error) {
    return false;
  }
  return true;
};

export const resetTokens = () => {
  const cookies = new Cookies();
  cookies.remove("token");
  cookies.remove("refreshToken");
};
