import Button from "@components/Button/Button";
import { styled } from "@mui/material";

export const StyledHeader = styled("header")`
  padding: 55px 0;
  margin-bottom: 65px;
`;

export const StyledHeaderButton = styled(Button)`
  border-radius: 15px;
  background: rgba(39, 39, 39, 0.7);
`;
