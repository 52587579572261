import { ButtonProps } from "@components/Button/ButtonTypes";
import MuiButton from "@mui/material/Button";

const Button = ({ className, children, ...rest }: ButtonProps) => {
  return (
    <MuiButton className={className} {...rest}>
      {children}
    </MuiButton>
  );
};
export default Button;
