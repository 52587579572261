import {
  CreateFileBody,
  CreateFileResponse,
  CreateImagesBody,
  CreateImagesResponse,
} from "@customTypes/api/models/file.model";
import { FileApiPaths } from "@redux/services/file/fileApiPaths";
import generalApi from "@redux/services/initialApi";

export const fileApi = generalApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Create file with transformation request body to formData
     */
    createFile: builder.mutation<CreateFileResponse, CreateFileBody>({
      query: ({ frontName, ...fileBody }) => {
        const formData = new FormData();
        formData.append("file", fileBody.file);

        return {
          url: FileApiPaths.CreateFile,
          method: "POST",
          body: formData,
          params: { frontName },
        };
      },
    }),

    /**
     * Create Images with transformation request body to formData
     */
    createImages: builder.mutation<CreateImagesResponse, CreateImagesBody>({
      query: ({ ...fileBody }) => {
        const multiFilesArray = Array.from(fileBody.multipartFiles);

        const formData = new FormData();

        multiFilesArray.forEach((elem) =>
          formData.append("multipartFiles", elem)
        );

        return {
          url: FileApiPaths.CreateImages,
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const { useCreateFileMutation, useCreateImagesMutation } = fileApi;
