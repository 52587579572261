import userReducer from "@redux/features/userSlice/userSlice";
import { rtkQueryErrorLogger } from "@redux/services/handlingErrors";
import { rtkQuerySuccessLogger } from "@redux/services/handlingSuccessRequests";
import generalApi from "@redux/services/initialApi";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { authApi } from "./services/auth/authApi";

export const store = configureStore({
  reducer: {
    [generalApi.reducerPath]: generalApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    user: userReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      generalApi.middleware,
      authApi.middleware,
      rtkQueryErrorLogger,
      rtkQuerySuccessLogger
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
