import MainContainer from "@components/MainContainer/MainContainer";
import RouterLink from "@components/RouterLink/RouterLink";
import { TypeStyledComponentWithMobileScreenProp } from "@customTypes/styleGlobalTypes";
import { styled } from "@mui/material";

export const StyledNotFoundPageMainContainer = styled(MainContainer, {
  shouldForwardProp: (prop) => prop !== "isMobileScreen",
})<TypeStyledComponentWithMobileScreenProp>`
  padding-top: 68px;
  height: ${({ isMobileScreen }) => (isMobileScreen ? "500px" : "610px")};
  text-align: center;
  position: relative;
  overflow-x: clip;
`;

export const StyledNotFoundPageLink = styled(RouterLink)`
  text-decoration-color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

export const StyledNotFoundPageImage = styled("img")`
  width: 100%;
  height: 100%;
`;

export const StyledNotFoundImage1Wrapper = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "isMobileScreen" && prop !== "isTableScreen",
})<TypeStyledComponentWithMobileScreenProp<{ isTableScreen: boolean }>>`
  position: absolute;
  top: ${({ isMobileScreen }) => (isMobileScreen ? 0 : "-68px")};
  width: ${({ isMobileScreen }) => (isMobileScreen ? "116px" : "233px")};
  height: ${({ isMobileScreen }) => (isMobileScreen ? "116px" : "233px")};
  ${({ isTableScreen }) => (isTableScreen ? "right: 60%" : "left: 191px;")};
`;

export const StyledNotFoundImage2Wrapper = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "isMobileScreen" && prop !== "isTableScreen",
})<TypeStyledComponentWithMobileScreenProp<{ isTableScreen: boolean }>>`
  position: absolute;
  width: ${({ isMobileScreen }) => (isMobileScreen ? "150px" : "300px")};
  height: ${({ isMobileScreen }) => (isMobileScreen ? "150px" : "300px")};
  ${({ isTableScreen }) => (isTableScreen ? "right: 82.21%" : "left: 0;")};
  top: ${({ isTableScreen }) =>
    isTableScreen
      ? "calc(578px / 2 - 300px / 2)"
      : "calc(678px / 2 - 300px / 2)"};
`;

export const StyledNotFoundImage3Wrapper = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "isMobileScreen" && prop !== "isTableScreen",
})<TypeStyledComponentWithMobileScreenProp<{ isTableScreen: boolean }>>`
  position: absolute;
  width: ${({ isMobileScreen }) => (isMobileScreen ? "132px" : "262px")};
  height: ${({ isMobileScreen }) => (isMobileScreen ? "132px" : "262px")};
  ${({ isTableScreen }) => (isTableScreen ? "right: 60%" : "left: 191px;")};
`;

export const StyledNotFoundImage4Wrapper = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "isMobileScreen" && prop !== "isTableScreen",
})<TypeStyledComponentWithMobileScreenProp<{ isTableScreen: boolean }>>`
  position: absolute;
  top: ${({ isMobileScreen }) => (isMobileScreen ? 0 : "-68px")};
  width: ${({ isMobileScreen }) => (isMobileScreen ? "102px" : "204px")};
  height: ${({ isMobileScreen }) => (isMobileScreen ? "102px" : "204px")};
  ${({ isTableScreen }) => (isTableScreen ? "left: 60%;" : "right: 191px")};
`;

export const StyledNotFoundImage5Wrapper = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "isMobileScreen" && prop !== "isTableScreen",
})<TypeStyledComponentWithMobileScreenProp<{ isTableScreen: boolean }>>`
  position: absolute;
  width: ${({ isMobileScreen }) => (isMobileScreen ? "129px" : "260px")};
  height: ${({ isMobileScreen }) => (isMobileScreen ? "127px" : "256px")};
  ${({ isTableScreen }) => (isTableScreen ? "left: 80%" : "right: 6%")};
  top: ${({ isTableScreen }) =>
    isTableScreen
      ? "calc(578px / 2 - 300px / 2)"
      : "calc(678px / 2 - 300px / 2)"};
`;

export const StyledNotFoundImage6Wrapper = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "isMobileScreen" && prop !== "isTableScreen",
})<TypeStyledComponentWithMobileScreenProp<{ isTableScreen: boolean }>>`
  position: absolute;
  width: ${({ isMobileScreen }) => (isMobileScreen ? "118px" : "237px")};
  height: ${({ isMobileScreen }) => (isMobileScreen ? "113px" : "227px")};
  ${({ isTableScreen }) => (isTableScreen ? "left: 60%;" : "right: 191px")};
`;
